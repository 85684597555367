import React, { useState, useEffect, useContext } from 'react';
import DefiWizardContext, { DefiWizardContextProps } from './DefiWizardContext';

interface Location {
  id: number;
  name: string;
  idOperator: number | null;
  idVendor: number | null;
  idDefibrillator: number | null;
  idDefibrillatorTracker: number | null;
  idCabinetSensor: number | null;
  idAddress: number | null;
  idContact: number | null;
  comment: string | null;
}

const LocationList: React.FC = () => { 
  const [locationList, setLocationList] = useState<Location[]>([]);
  const [selectedLocationId, setSelectedLocationId] = useState<number | 'new'>('new');
  const [newLocation, setNewLocation] = useState<Location>({
    id: 0,
    name: '',
    idOperator: null,
    idVendor: null,
    idDefibrillator: null,
    idDefibrillatorTracker: null,
    idCabinetSensor: null,
    idAddress: null,
    idContact: null,
    comment: null,
  });

  const context = useContext(DefiWizardContext);

  useEffect(() => {
    async function fetchLocations() {
      try {
        const response = await fetch('/api/Defis/GetLocationList');
        if (!response.ok) {
          throw new Error(`Error fetching locations: ${response.statusText}`);
        }
        const dataResponse = await response.json();
        if (dataResponse.status === "success") {
          const data: Location[] = dataResponse.jsn;
          setLocationList(data);
        } else {
          console.error('Error fetching locations: Response status is not success');
        }
      } catch (error) {
        console.error('Error fetching locations:', error);
      }
    }
    fetchLocations();
  }, []);

  useEffect(() => {
    if (context && context.idLocation !== 0) {
      setSelectedLocationId(context.idLocation);
    }
  }, [context]);

  useEffect(() => {
    if (context && selectedLocationId === 'new') {
      setNewLocation(prevLocation => ({
        ...prevLocation,
        idOperator: context.idOperator,
        idVendor: context.idVendor,
        idDefibrillator: context.idDefibrillator,
        idDefibrillatorTracker: context.idDefibrillatorTracker,
        idCabinetSensor: context.idCabinetSensor,
        idAddress: context.idAddress,
        idContact: context.idContact
      }));
    } else {
      const foundLocation = locationList.find(op => op.id === selectedLocationId);
      if (foundLocation) setNewLocation(foundLocation);
    }
  }, [selectedLocationId, locationList, context]);


  if (!context) {
    return null;
  }

  const {
    idLocation,
    setIdLocation,
    idOperator,
    idVendor,
    idDefibrillator,
    idDefibrillatorTracker,
    idCabinetSensor,
    idAddress,
    idContact,
    userAccessLevel
  } = context;

  const isDisabled = userAccessLevel === 0;

  const handleNewLocationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setNewLocation((prevLocation) => ({
      ...prevLocation,
      [event.target.name]: value,
    }));
  };

  const handleDropdownChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setSelectedLocationId(value === 'new' ? 'new' : parseInt(value));
  };

  const handleSaveLocation = async () => {
    try {
      // Merge newLocation with updated values from the context
      const updatedLocation = {
        ...newLocation,
        idOperator: context?.idOperator,
        idVendor: context?.idVendor,
        idDefibrillator: context?.idDefibrillator,
        idDefibrillatorTracker: context?.idDefibrillatorTracker,
        idCabinetSensor: context?.idCabinetSensor,
        idAddress: context?.idAddress,
        idContact: context?.idContact,
      };
  
      const response = await fetch('/api/Defis/UpsertLocation', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedLocation), // Use updatedLocation here
      });
  
      if (!response.ok) {
        throw new Error(`Error saving location: ${response.statusText}`);
      }
  
      const serverResponse = await response.json();
      const savedLocation: Location = serverResponse.jsn;console.log("Saved location:", savedLocation); // Log the saved location
  
      // Check if the location already exists in the list
      const index = locationList.findIndex(op => op.id === savedLocation.id);
  
      if (index !== -1) {
        // Update existing location in the list
        setLocationList((prevList) => {
          const updatedList = [...prevList];
          updatedList[index] = savedLocation;
          console.log("Updated Location List:", updatedList); // Log the updated list
          return updatedList;
        });
      } else {
        // Add new location to the list
        setLocationList((prevList) => {
          const updatedList = [...prevList, savedLocation];
          console.log("Updated Location List:", updatedList); // Log the updated list
          return updatedList;
        });
      }
  
      // Select the newly saved location
      console.log("Setting selected location ID:", savedLocation.id); // Log selected location ID
      setSelectedLocationId(savedLocation.id);
      setIdLocation(savedLocation.id);
  
    } catch (error) {
      console.error('Error saving location:', error);
    }
  };
  
    
  
  return (
    <div className="list-container">
      <h3 className="list-header">Location</h3>
      <select 
        value={selectedLocationId} 
        onChange={handleDropdownChange} 
        className="list-select"
        disabled={isDisabled}>
        <option value="new">Create New</option>
        {locationList.map((location) => (
          <option key={location.id} value={location.id}>
            {location.name}
          </option>
        ))}
      </select>

      <h3 className="list-header">
        {selectedLocationId === 'new' ? 'New Location' : 'Edit Location'}
      </h3>
      
      <input
        type="text"
        name="name"
        placeholder="Location Name"
        value={newLocation.name}
        onChange={handleNewLocationChange}
        className="list-input"
        disabled={isDisabled}
      />

      <button 
        onClick={handleSaveLocation} 
        className={isDisabled ? "list-input-button-disabled" : "list-input-button"} 
        disabled={isDisabled}>
        {selectedLocationId === 'new' ? 'Save New Location' : 'Update Location'}
      </button>
    </div>
  );
};

export default LocationList;
