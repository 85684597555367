import React, { useState, useEffect, useContext } from 'react';
import DefiWizardContext, { DefiWizardContextProps } from './DefiWizardContext';

interface Address {
  id: number;
  postalCode: string;
  city: string;
  street: string;
  houseNumber: string;
  latitude: number;
  longitude: number;
}

const AddressList: React.FC = () => { 
  const context = useContext(DefiWizardContext);
  const { idAddress, setIdAddress, userAccessLevel } = context as DefiWizardContextProps;
  const isDisabled = userAccessLevel === 0; 
  
  const [addressList, setAddressList] = useState<Address[]>([]);
  const [selectedAddressId, setSelectedAddressId] = useState<number | 'new'>('new');
  const [newAddress, setNewAddress] = useState<Address>({
    id: 0,
    postalCode: '',
    city: '',
    street: '',
    houseNumber: '',
    latitude: 0,
    longitude: 0,
  });

  useEffect(() => {
    if (!context) return;

    async function fetchAddresses() {
      try {
        const response = await fetch('/api/Defis/GetAddressList');
        if (!response.ok) {
          throw new Error(`Error fetching addresses: ${response.statusText}`);
        }
        const dataResponse = await response.json();
        if (dataResponse.status === "success") {
          const data: Address[] = dataResponse.jsn;
          setAddressList(data);
        } else {
          console.error('Error fetching addresses: Response status is not success');
        }
      } catch (error) {
        console.error('Error fetching addresses:', error);
      }
    }
    
    fetchAddresses();
  }, []);


  useEffect(() => {
    if (!context) return;

    setSelectedAddressId(context.idAddress);
  }, [context?.idAddress]);

  useEffect(() => {
    if (selectedAddressId === 'new') {
      setNewAddress({
        id: 0,
        postalCode: '',
        city: '',
        street: '',
        houseNumber: '',
        latitude: 0,
        longitude: 0,
      });
    } else {
      const foundAddress = addressList.find(a => a.id === selectedAddressId);
      if (foundAddress) setNewAddress(foundAddress);
    }
  }, [selectedAddressId, addressList]);

  const handleNewAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewAddress((prevAddress) => ({
      ...prevAddress,
      [event.target.name]: event.target.value,
    }));
  };

  const handleDropdownChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setSelectedAddressId(value === 'new' ? 'new' : parseInt(value));
  };

  const handleSaveAddress = async () => {
    try {
      const response = await fetch('/api/Defis/UpsertAddress', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newAddress),
      });
  
      if (!response.ok) {
        throw new Error(`Error saving address: ${response.statusText}`);
      }
  
      const serverResponse = await response.json();
      const savedAddress: Address = serverResponse.jsn;
      console.log("Saved address:", savedAddress); // Log the saved address
  
      // Check if the address already exists in the list
      const index = addressList.findIndex(a => a.id === savedAddress.id);
  
      if (index !== -1) {
        // Update existing address in the list
        setAddressList((prevList) => {
          const updatedList = [...prevList];
          updatedList[index] = savedAddress;
          console.log("Updated Address List:", updatedList); // Log the updated list
          return updatedList;
        });
      } else {
        // Add new address to the list
        setAddressList((prevList) => {
          const updatedList = [...prevList, savedAddress];
          console.log("Updated Address List:", updatedList); // Log the updated list
          return updatedList;
        });
      }
  
      // Select the newly saved address
      console.log("Setting selected address ID:", savedAddress.id); // Log selected address ID
      setSelectedAddressId(savedAddress.id);
      setIdAddress(savedAddress.id);
  
    } catch (error) {
      console.error('Error saving address:', error);
    }
  };
  

  return (
    <div className="list-container">
      <h3 className="list-header">Address</h3>
      <select value={selectedAddressId} onChange={handleDropdownChange} className="list-select" disabled={isDisabled}>
        <option value="new">Create New</option>
        {addressList.map((address) => (
          <option key={address.id} value={address.id}>
            {address.street}, {address.city}
          </option>
        ))}
        
      </select>
      
      <h3 className="list-header">{selectedAddressId === 'new' ? 'New Address' : 'Edit Address'}</h3>
      <input
        type="text"
        name="postalCode"
        placeholder="Postal Code"
        value={newAddress.postalCode}
        onChange={handleNewAddressChange}
        className="list-input"
        disabled={isDisabled}
      />
      <input
        type="text"
        name="city"
        placeholder="City"
        value={newAddress.city}
        onChange={handleNewAddressChange}
        className="list-input"
        disabled={isDisabled}
      />
      <input
        type="text"
        name="street"
        placeholder="Street"
        value={newAddress.street}
        onChange={handleNewAddressChange}
        className="list-input"
        disabled={isDisabled}
      />
      <input
        type="text"
        name="houseNumber"
        placeholder="House Number"
        value={newAddress.houseNumber}
        onChange={handleNewAddressChange}
        className="list-input"
        disabled={isDisabled}
      />
      <input
        type="text"
        name="latitude"
        placeholder="Latitude"
        value={newAddress.latitude}
        onChange={handleNewAddressChange}
        className="list-input"
        disabled={isDisabled}
      />
      <input
        type="text"
        name="longitude"
        placeholder="Longitude"
        value={newAddress.longitude}
        onChange={handleNewAddressChange}
        className="list-input"
        disabled={isDisabled}
      />
      <button onClick={handleSaveAddress} className={isDisabled ? "list-input-button-disabled" : "list-input-button"} disabled={isDisabled}>
        {selectedAddressId === 'new' ? 'Save New Address' : 'Update Address'}
        
      </button>
    </div>
  );
  
};

export default AddressList;
