import React, { ChangeEvent, useState } from 'react';
import { apiUrl } from '../App';

interface Location {
  id: number;
  name?: string;
  operatorName?: string;
  vendorName?: string;
  street?: string;
  houseNumber?: string;
  city?: string;
  postalCode?: string;
}

interface SurveyProps {
  sessionId: string;
  address: string;
  location: { lat: string; lon: string };
  setCurrentStep: React.Dispatch<React.SetStateAction<'requestCode' | 'verifyCode' | 'locationPermission' | 'enterAddress' | 'confirmLocation' | 'uploadPhotos' | 'submitted'>>;
  setStatusMessage: (message: string) => void;
}

const Survey: React.FC<SurveyProps> = ({ sessionId, address, location, setCurrentStep, setStatusMessage }) => {
  const [photos, setPhotos] = useState<File[]>([]);
  const [comments, setComments] = useState('');
  const [questionAnswer, setQuestionAnswer] = useState({
    accessible: 'No',
    visibleDamage: 'No',
    padsAvailable: 'No',
    batteryIndicator: 'No',
    cabinetLocked: 'No',
  });
  const [isProcessing, setIsProcessing] = useState(false);

  const style: { [key: string]: React.CSSProperties } = {
    container: {
      padding: '20px',
      fontFamily: 'Arial',
      display: 'flex',
      flexDirection: 'column',
      minHeight: '66vh',
      alignItems: 'center',
      justifyContent: 'center'
    },
    questionBlock: {
      width: '100%',
      maxWidth: '500px',
      marginBottom: '30px',
      textAlign: 'center',
      border: '1px solid #ddd',
      borderRadius: '8px',
      padding: '20px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    image: {
      width: '100%',
      maxWidth: '300px',
      marginBottom: '20px'
    },
    input: {
      margin: '10px 0',
      padding: '10px',
      width: '100%',
      maxWidth: '300px',
      boxSizing: 'border-box'
    },
    button: {
      padding: '10px 20px',
      margin: '10px',
      cursor: 'pointer',
      width: '100%',
      maxWidth: '200px',
      boxSizing: 'border-box'
    },
    label: { display: 'block', marginTop: '20px' }
  };

  const handleUploadPhotos = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setPhotos(Array.from(event.target.files));
    }
  };

  const handleSubmit = async () => {
    console.log('Session ID:', sessionId);

    const imagePromises = photos.map(photo => {
      return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = error => reject(error);
        reader.readAsDataURL(photo);
      });
    });

    const imagesBase64 = await Promise.all(imagePromises);

    const surveyData = {
      IdAuthCode: parseInt(sessionId, 10),
      Survey: {
        comments,
        ...questionAnswer,
        address: address,
        location: `${location.lat},${location.lon}`,  // Convert location to string
      },
      Img: imagesBase64[0] || ""
    };

    console.log('Survey Data:', surveyData);

    try {
      const response = await fetch(`${apiUrl}/Defis/SubmitSurvey`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(surveyData)
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to submit survey');
      }

      const result = await response.json();
      console.log('Submission successful:', result.message);
      setCurrentStep('submitted');
      setStatusMessage('Survey submitted successfully!');
    } catch (error) {
      console.error('Error submitting survey:', error);
      setStatusMessage('Failed to submit survey. Please try again.');
    }
  };

  return (
    <div style={style.container}>
      <div style={style.questionBlock}>
        <img src="https://defiapp.at/images/survey/001.jpg" alt="Is the site accessible?" style={style.image} />
        <label>Is the AED publicly accessible? (Y/N)</label>
        <select
          value={questionAnswer.accessible}
          onChange={(e) => setQuestionAnswer({ ...questionAnswer, accessible: e.target.value })}
          style={style.input}
        >
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
      </div>
      <div style={style.questionBlock}>
        <img src="https://defiapp.at/images/survey/002.jpg" alt="Is there visible damage?" style={style.image} />
        <label>Is there visible damage? (Y/N)</label>
        <select
          value={questionAnswer.visibleDamage}
          onChange={(e) => setQuestionAnswer({ ...questionAnswer, visibleDamage: e.target.value })}
          style={style.input}
        >
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
      </div>
      <div style={style.questionBlock}>
        <img src="https://defiapp.at/images/survey/003.jpg" alt="Are the pads available and in good condition?" style={style.image} />
        <label>Are the pads available and in good condition? (Y/N)</label>
        <select
          value={questionAnswer.padsAvailable}
          onChange={(e) => setQuestionAnswer({ ...questionAnswer, padsAvailable: e.target.value })}
          style={style.input}
        >
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
      </div>
      <div style={style.questionBlock}>
        <img src="https://defiapp.at/images/survey/004.jpg" alt="Is the battery indicator showing sufficient charge?" style={style.image} />
        <label>Is the battery indicator showing sufficient charge? (Y/N)</label>
        <select
          value={questionAnswer.batteryIndicator}
          onChange={(e) => setQuestionAnswer({ ...questionAnswer, batteryIndicator: e.target.value })}
          style={style.input}
        >
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
      </div>
      <div style={style.questionBlock}>
        <img src="https://defiapp.at/images/survey/005.jpg" alt="Is the cabinet locked and secure?" style={style.image} />
        <label>Is the cabinet locked and secure? (Y/N)</label>
        <select
          value={questionAnswer.cabinetLocked}
          onChange={(e) => setQuestionAnswer({ ...questionAnswer, cabinetLocked: e.target.value })}
          style={style.input}
        >
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
      </div>
      <div style={style.questionBlock}>
        <img src="https://defiapp.at/images/survey/006.jpg" alt="Upload photos of the AED and its surroundings" style={style.image} />
        <label>Upload photos of the AED and its surroundings:</label>
        <input
          type="file"
          multiple
          onChange={handleUploadPhotos}
          style={style.input}
        />
      </div>
      <div style={style.questionBlock}>
        <img src="https://defiapp.at/images/survey/007.jpg" alt="Add any additional comments" style={style.image} />
        <label>Add any additional comments:</label>
        <input
          type="text"
          value={comments}
          onChange={(e) => setComments(e.target.value)}
          placeholder="Add comments"
          style={style.input}
        />
      </div>
      <button 
        onClick={() => {
          setIsProcessing(true);
          handleSubmit().finally(() => setIsProcessing(false));
        }}
        disabled={isProcessing}
        style={style.button}
      >
        Submit
      </button>
    </div>
  );
};

export default Survey;
