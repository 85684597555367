import React, { useContext, useEffect, useRef, useState } from 'react';
import L, { LeafletMouseEvent, LatLngExpression } from 'leaflet';

import greenMarkerIcon from '../resources/marker-icon-green.png';
import lightningMarkerIcon from '../resources/marker-icon-green-lightning.png';
import orangeMarkerIcon from '../resources/marker-icon-orange.png';
import redMarkerIcon from '../resources/marker-icon-red.png';
import redLocationIcon from '../resources/location-icon-red.png';
import heartbeatMarkerIcon from '../resources/heartbeat2.gif';
import lifeguardMarkerIcon from '../resources/marker-lifeguard2.png';
import cabinetClosedGreenMarkerIcon from '../resources/marker-cabinet-closed-green.png';
import cabinetClosedRedMarkerIcon from '../resources/marker-cabinet-closed-red.png';
import cabinetClosedGrayMarkerIcon from '../resources/marker-cabinet-closed-gray.png';

import { apiUrl } from '../App';

import { MapCenterContext } from './MapContext';

const createHoverIcon = (iconUrl: string) => {
  return L.icon({
    iconUrl,
    iconSize: [75, 62],
    iconAnchor: [38, 50],
    popupAnchor: [0, -41],
  });
};

const initialPosition: LatLngExpression = [48.2082, 16.3738];

interface DefiMapProps {
  email: string;
}

const DefiMap: React.FC<DefiMapProps> = ({ email }) => {
  const mapRef = useRef<L.Map | null>(null);
  const [markers, setMarkers] = useState<L.Marker[]>([]);
  const [heartBeatMarkers, setHeartBeatMarkers] = useState<L.Marker[]>([]);
  const [userLocation, setUserLocation] = useState<LatLngExpression | null>(initialPosition);

  const { mapCenter } = useContext(MapCenterContext);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const [overlayImage, setOverlayImage] = useState<string | null>(null);

  const handleSearch = async () => {
    const url = `https://nominatim.openstreetmap.org/search?format=json&q=${searchQuery}`;
    const response = await fetch(url);
    const data = await response.json();
    if (data[0]) {
      const { lat, lon } = data[0];
      if (mapRef.current) {
        mapRef.current.flyTo([lat, lon], 16);
      }
    } else {
      console.log('No results found.');
    }
  };

  const getUserLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      const userLoc: LatLngExpression = [position.coords.latitude, position.coords.longitude];
      setUserLocation(userLoc);
      if (mapRef.current) {
        const marker = L.marker(userLoc, {
          icon: L.icon({
            iconUrl: redLocationIcon,
            iconSize: [25, 30],
            iconAnchor: [12.5, 41],
            popupAnchor: [0, -41],
          }),
        });
        marker.addTo(mapRef.current);
      }
    }, (error) => {
      console.log(error);
      setUserLocation(initialPosition);
    });
  };

  useEffect(() => {
    getUserLocation();
  }, []);

  useEffect(() => {
    if (!mapRef.current) {
      mapRef.current = new L.Map('map', {
        center: userLocation || initialPosition,
        zoom: 16,
      });

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      }).addTo(mapRef.current);

      pollData();
    } else if (mapRef.current && userLocation) {
      mapRef.current.setView(userLocation);
    }
  }, [userLocation]);

  useEffect(() => {
    if (mapRef.current && mapCenter) {
      mapRef.current.flyTo([mapCenter[0], mapCenter[1]], 16);
    }
  }, [mapCenter]);

  const loadData = async (initialLoad = false) => {
    console.log('initialLoad:', initialLoad);
    var callAgain = false;
    if (markers.length == 0) {
      callAgain = true;
      var operatorFilter = email != null && email != '?init=1' ? `?init=1&email=${email}` : '';
    } else {
      var operatorFilter = email != null && email != '' ? `?email=${email}` : '';
    }
    const response = await fetch(`${apiUrl}/Defis/GetMarkerDataViaView`+ operatorFilter);
    const data = await response.json();
    data.sort((a: any, b: any) => {
      return a.deviceType - b.deviceType;
    });

    if (true) {
      markers.forEach((marker) => {
        mapRef.current!.removeLayer(marker);
        marker.remove();
      });
      heartBeatMarkers.forEach((marker) => {
        mapRef.current!.removeLayer(marker);
        marker.remove();
      });
    }

    if (true) {
      const alarm = async (deviceId: any) => {
        try {
          const response = await fetch(`${apiUrl}/Defis/getalarm?deviceId=${deviceId}`);
          const data = await response.json();
          console.log("Alarm response:", data);
        } catch (error) {
          console.error("Error in alarm function:", error);
        }
      };
      (window as any).alarm = alarm;

      data.forEach((item: Record<string, any>) => {
        const iconUrl = getIconUrl(item);

        const icon = L.icon({
          iconUrl,
          iconSize: [25, 41],
          iconAnchor: [12.5, 41],
          popupAnchor: [0, -41],
        });

        const hoverIconUrl = heartbeatMarkerIcon;
        const hoverIcon = createHoverIcon(hoverIconUrl);

        const marker = L.marker([item.Lat, item.Long], { icon });
        if (item.deviceType.toString() == "3") {
          const heartbeatMarker = L.marker([item.Lat, item.Long], { icon: hoverIcon });
          heartbeatMarker.setOpacity(0);
          heartbeatMarker.addTo(mapRef.current!);

          marker.on('mouseover', () => {
            marker.setOpacity(0);
            heartbeatMarker.setOpacity(1);
          });

          marker.on('mouseout', () => {
            marker.setOpacity(1);
            heartbeatMarker.setOpacity(0);
          });

          heartBeatMarkers.push(heartbeatMarker);
        }

        let popupContent = '<div>';
        if (!item.Standortkennung.startsWith("Lebensretter") && !item.Standortkennung.startsWith("GR")) {
          popupContent += `
          <b>
            <font color="red">Adresse: </font>
          </b> ${item.Strasse} ${item.Hausnummer != null ? item.Hausnummer : ''} ${item.Postleitzahl} ${item.Stadt}
          <br />
          <b>
            <font color="red">Standortkennung: </font>
          </b> ${item.Standortkennung != null ? item.Standortkennung : 'Location ID is missing!'}
          <br />
          `;
        } else {
          popupContent += `
          <b>
            <font color="red">Lebensretter-ID: </font>
          </b> ${item.Standortkennung}
          <br />
          `;
        }

        if (item.deviceKey != "" && !item.Standortkennung.toString().startsWith('Lebensretter') && !item.Standortkennung.toString().startsWith('GR')) {
          popupContent += `
          <b>
            <font color="red">Gerät-ID: </font>
          </b> ${item.deviceKey}
          <br />
          `;

          if (item.deviceType.toString() == "3") {
            popupContent += `
              <b>
                <font color="red">Zeitstempel: </font>
              </b> ${item.Zeitstempel}
              <br />
              <b>
                <font color="red">Temperatur: </font>
              </b> ${item.Temperatur}
              <br />
              <b>
                <font color="red">Luftfeuchtigkeit: </font>
              </b> ${item.Luftfeuchtigkeit}
              <br />
              <b>
                <font color="red">Defibrillator: </font>
              </b> ${item.Entnahme === null ? 'N/A' : (item.Entnahme === '1' ? 'entnommen' : 'präsent')}
              <br />
              <b>
                <font color="red">Schranktür: </font>
              </b> ${item.Schranktuer === null ? 'N/A' : (item.Schranktuer === '1' ? 'offen' : 'geschlossen')}
              <br />
              <b>
                <font color="red">Service: </font>
              </b> ${item.Service === null ? 'N/A' : (item.Service === '1' ? 'service' : 'scharfgestellt')}
              <br />
              <button onclick="window.alarm('${item.deviceKey}')" class="alarmButton">
                <b>
                  <font color="red">ALARM</font>
                </b>
              </button>
            `;
          }
        }
        popupContent += '</div>';

        marker.bindPopup(popupContent).addTo(mapRef.current!);

        marker.on('click', () => {
          handleClickMarker(item.deviceKey);
        });

        marker.on('popupopen', async () => {
          try {
            const response = await fetch(`${apiUrl}/Defis/GetImages?idLocation=${item.idLocation}`);
            const imgData = await response.json();

            let imgContent = '<div style="text-align: center;">';
            for (let i = 1; i <= 3; i++) {
              const imgURL = imgData[0][`img${i}`];
              if (imgURL) {
                imgContent += `<img src="${imgURL}" class="thumbnail" data-img="${imgURL}" />`;
              }
            }
            imgContent += '</div>';

            marker.setPopupContent(popupContent + imgContent);
          } catch (error) {
            console.error('Error fetching images:', error);
          }
        });

        markers.push(marker);
      });
    }
    if (callAgain) {
      loadData();
    }
  };

  const pollData = async () => {
    const poll = async () => {
      await loadData(true);
      setTimeout(poll, 30000);
    };
    poll();
  };

  const handleClickMarker = async (deviceId: string) => {
    if (deviceId) {
      console.log('Clicked marker:', deviceId);
    }
  };

  const getIconUrl = (defi: any) => {

    // Check if the defibrillator has been removed and if so, use the gray icon
    if (defi.Entnahme === '1') {
      return cabinetClosedGrayMarkerIcon;
    }

    if (defi.deviceType.toString() == "2") {
      return lifeguardMarkerIcon;
    } else if (defi.deviceType.toString() == "1") {
      return redMarkerIcon;
    } else if (defi.deviceType.toString() == "3") {
      return cabinetClosedRedMarkerIcon;
    } else {
      return cabinetClosedGreenMarkerIcon;
    }
  };

  useEffect(() => {
    document.addEventListener('click', (e) => {
      if ((e.target as HTMLElement).classList.contains('thumbnail')) {
        setOverlayImage((e.target as HTMLElement).getAttribute('data-img'));
      }
    });
  }, []);

  return (
    <div style={{ flex: 1, position: 'relative' }}>
      <div id="map" style={{ height: '80vh', zIndex: 1 }} />
      <div style={{ 
          position: 'absolute', 
          right: '10px', 
          top: '5px',
          zIndex: 500, 
          background: 'white', 
          borderRadius: '8px', 
          padding: '10px', 
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' 
        }}
      >
        <input 
          type="text" 
          value={searchQuery} 
          onChange={(e) => setSearchQuery(e.target.value)} 
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSearch();
            }
          }}
          placeholder="Enter address"
          style={{ marginRight: '10px', padding: '5px', borderRadius: '4px' }}
        />
        <button 
          onClick={handleSearch}
          style={{ 
            cursor: 'pointer',
            backgroundColor: 'transparent',
            border: 'none',
            fontSize: '15px'
          }}
        >
          🔍
        </button>
        {overlayImage && (
        <div className="overlay" onClick={() => setOverlayImage(null)}>
          <img src={overlayImage} alt="Full Size" />
        </div>
      )}
      </div>
    </div>
  );
};

export default DefiMap;
