import React, { useState, useEffect, useContext } from 'react';
import DefiWizardContext, { DefiWizardContextProps } from './DefiWizardContext';

interface DeviceListProps {
  idDevice: number;
  idDeviceType: number;
}

type Device = {
  id: number;
  deviceKey: string;
  idManufacturer: number;
  idModel: number;
  idDeviceType: number;
  idApiCredential: number;
  comment?: string;
};

type Manufacturer = {
  id: number;
  name: string;
};

type Model = {
  id: number;
  name: string;
};

type DeviceType = {
  id: number;
  name: string;
};

type ApiCredential = {
  id: number;
  apiUrl: string;
};

const DeviceList: React.FC<DeviceListProps> = ({ idDevice, idDeviceType }) => {
  
  const [devices, setDevices] = useState<Device[]>([]);
  const [manufacturers, setManufacturers] = useState<Manufacturer[]>([]);
  const [models, setModels] = useState<Model[]>([]);
  const [deviceTypes, setDeviceTypes] = useState<DeviceType[]>([]);
  const [apiCredentials, setApiCredentials] = useState<ApiCredential[]>([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState<string | number>("new"); 
  const [newDevice, setNewDevice] = useState<Device>({
    id: 0,
    deviceKey: '',
    idManufacturer: 0,
    idModel: 0,
    idDeviceType: 0,
    idApiCredential: 0,
    comment: '',
  });

  useEffect(() => {
    async function fetchData() {
      // Fetch logic for devices
      const [deviceData, manufacturerData, modelData, deviceTypeData, apiCredentialData] = await Promise.all([
        fetch('/api/Defis/GetDeviceList'),
        fetch('/api/Defis/GetManufacturerList'),
        fetch('/api/Defis/GetModelList'),
        fetch('/api/Defis/GetDeviceTypeList'),
        fetch('/api/Defis/GetApiCredentialsList')
      ]);

      //setDevices((await deviceData.json()).jsn); 
      // filter by idDeviceType
      const deviceList = (await deviceData.json()).jsn;
      const filteredDeviceList = deviceList.filter((device: Device) => device.idDeviceType === idDeviceType);
      setDevices(filteredDeviceList);
      setManufacturers((await manufacturerData.json()).jsn);
      setModels((await modelData.json()).jsn);
      setDeviceTypes((await deviceTypeData.json()).jsn);
      setApiCredentials((await apiCredentialData.json()).jsn);
    }

    fetchData();
  }, [idDeviceType]);

  useEffect(() => {
    if (idDevice !== 0) {
      setSelectedDeviceId(idDevice);
    } 
  }, [idDevice]);

  useEffect(() => {
    // When setting a new device, set idDeviceType based on the prop
    if (selectedDeviceId === 'new') {
      setNewDevice({
        id: 0,
        deviceKey: '',
        idManufacturer: 0,
        idModel: 0,
        idDeviceType: idDeviceType, // set it here
        idApiCredential: 0,
        comment: '',
      });
    } else {
      const foundDevice = devices.find(device => device.id === selectedDeviceId);
      if (foundDevice) {
        setNewDevice(foundDevice);
      } else {
        // This is to handle edge cases where the selectedDeviceId doesn't exist in the list.
        // You might want to handle this differently based on your application logic.
        setNewDevice({
          id: 0,
          deviceKey: '',
          idManufacturer: 0,
          idModel: 0,
          idDeviceType: idDeviceType,
          idApiCredential: 0,
          comment: '',
        });
      }
    }
  }, [selectedDeviceId, devices, idDeviceType]);

  useEffect(() => {
    // Initialize state variables when the component mounts
    if (context) {
      const { updateIdByDeviceType, idDefibrillatorTracker, idCabinetSensor, idDefibrillator } = context;
      let initialSelectedDeviceId: string | number = 'new';
      switch (idDeviceType) {
        case 1:
          initialSelectedDeviceId = idDefibrillatorTracker || 'new';
          break;
        case 3:
          initialSelectedDeviceId = idCabinetSensor || 'new';
          break;
        case 4:
          initialSelectedDeviceId = idDefibrillator || 'new';
          break;
        default:
          initialSelectedDeviceId = 'new';
      }
      setSelectedDeviceId(initialSelectedDeviceId);
    }
  
    // Cleanup logic when the component unmounts
    return () => {
      setDevices([]);
      setManufacturers([]);
      setModels([]);
      setDeviceTypes([]);
      setApiCredentials([]);
      setSelectedDeviceId('new');
      setNewDevice({
        id: 0,
        deviceKey: '',
        idManufacturer: 0,
        idModel: 0,
        idDeviceType: 0,
        idApiCredential: 0,
        comment: '',
      });
    };
  }, [idDeviceType]);
  

  //const { updateIdByDeviceType } = useContext<DefiWizardContextProps>(DefiWizardContext);
  const context = useContext(DefiWizardContext);
  if (!context) {
    return null;
  }
  const { updateIdByDeviceType, idDefibrillatorTracker, idCabinetSensor, idDefibrillator, userAccessLevel} = context;
  const isDisabled = userAccessLevel === 0; 

  const handleDeviceChange = (key: string, value: number | string) => {
    setNewDevice(prev => ({ ...prev, [key]: value }));
  };

  const handleSaveDevice = async () => {
    try {
      const response = await fetch('/api/Defis/UpsertDevice', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newDevice),
      });
      if (!response.ok) {
        throw new Error(`Error saving device: ${response.statusText}`);
      }
      //const savedDevice: Device = await response.json();
      const serverResponse = await response.json();
      const savedDevice: Device = serverResponse.jsn; 
      if (savedDevice && savedDevice.id) {
        // Refresh the list of devices to make sure it's updated
        const deviceData = await fetch('/api/Defis/GetDeviceList');
        const deviceList = (await deviceData.json()).jsn;
        const filteredDeviceList = deviceList.filter((device: Device) => device.idDeviceType === idDeviceType);
        setDevices(filteredDeviceList);
  
        // Update selected device ID
        setSelectedDeviceId(savedDevice.id);
        updateIdByDeviceType(idDeviceType, savedDevice.id);
      }
    } catch (error) {
      console.error('Error saving device:', error);
    }
  };
  

  const getDeviceTitle = () => {
    switch (idDeviceType) {
      case 1:
        return 'Device (Defibrillator Tracker)';
      case 3:
        return 'Device (Cabinet Sensor)';
      case 4:
        return 'Device (Defibrillator)';
      default:
        return 'Device';
    }
  };

  return (
    <div className="device-container">
      <h3 className="device-header">{getDeviceTitle()}</h3>
      <select 
        value={selectedDeviceId} 
        onChange={e => setSelectedDeviceId(e.target.value === 'new' ? 'new' : parseInt(e.target.value))}
        className="device-select"
        disabled={isDisabled}
      >
        <option value="new">Create New</option>
        {devices.map(device => (
          <option key={device.id} value={device.id}>
            {device.deviceKey}
          </option>
        ))}
      </select>
  
      <h3 className="device-header">{selectedDeviceId === 'new' ? 'New Device' : 'Edit Device'}</h3>
  
      <div className="device-form-group">
        <label className="device-label">
          Manufacturer:
          <select 
            value={newDevice.idManufacturer} 
            onChange={e => handleDeviceChange('idManufacturer', parseInt(e.target.value))}
            className="device-select"
            disabled={isDisabled}
          >
            {manufacturers.map(m => (
              <option key={m.id} value={m.id}>
                {m.name}
              </option>
            ))}
          </select>
        </label>
      </div>
  
      <div className="device-form-group">
        <label className="device-label">
          Model:
          <select 
            value={newDevice.idModel} 
            onChange={e => handleDeviceChange('idModel', parseInt(e.target.value))}
            className="device-select"
            disabled={isDisabled}
          >
            {models.map(m => (
              <option key={m.id} value={m.id}>
                {m.name}
              </option>
            ))}
          </select>
        </label>
      </div>
  
      <div className="device-form-group">
        <label className="device-label">
          Device Type:
          <select 
            value={idDeviceType} 
            disabled={true} 
            className="device-select"
            
          >
            {deviceTypes.map(dt => (
              <option key={dt.id} value={dt.id}>
                {dt.name}
              </option>
            ))}
          </select>
        </label>
      </div>
  
      <div className="device-form-group">
        <label className="device-label">
          API Credential:
          <select 
            value={newDevice.idApiCredential} 
            onChange={e => handleDeviceChange('idApiCredential', parseInt(e.target.value))}
            className="device-select"
            disabled={isDisabled}
          >
            {apiCredentials.map(ac => (
              <option key={ac.id} value={ac.id}>
                {ac.apiUrl}
              </option>
            ))}
          </select>
        </label>
      </div>
  
      <div className="device-form-group">
        <input 
          type="text" 
          name="deviceKey" 
          placeholder="Device Key" 
          value={newDevice.deviceKey} 
          onChange={e => handleDeviceChange('deviceKey', e.target.value)}
          className="device-input"
          disabled={isDisabled}
        />
         <input 
          type="text" 
          name="comment" 
          placeholder="Comment" 
          value={newDevice.comment || ''} 
          onChange={e => handleDeviceChange('comment', e.target.value)}
          className="device-input"
          disabled={isDisabled}
        />
      </div>
  
      <button onClick={handleSaveDevice} className={isDisabled ? "device-button-disabled" : "device-button"} disabled={isDisabled}>
        {selectedDeviceId === 'new' ? 'Save New Device' : 'Update Device'}
      </button>
    </div>
  );
  
};

export default DeviceList;
