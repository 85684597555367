import React from 'react';

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="privacy-policy-container">
      <div className="header">
        <h1>Privacy Policy / Datenschutzbestimmungen</h1>
      </div>

      <div className="content">
        <h2>Data Protection Regulation</h2>
        <p>
          Welcome to KISS IT Solutions e.U., where your privacy and data security are our top priorities. This Data Protection Regulation outlines our practices and obligations in collecting, using, and safeguarding your personal data under the stringent standards of the General Data Protection Regulation (GDPR/DSGVO). Our commitment extends beyond compliance, aiming to foster transparency and trust with all our users.
        </p>
        <h3>Information We Collect</h3>
        <p>
          At KISS IT Solutions e.U., we collect various types of data to provide and improve our services effectively. These include:
        </p>
        <ul>
          <li>Personal Data: Information that can be used to contact or identify you. We collect personal data in ways that are clear and transparent, and we use this data solely for the purposes outlined in this document.</li>
          <li>Usage Data: This includes data collected automatically either generated by the use of the service or from the service infrastructure itself (for example, the duration of a page visit). It helps us understand how our services are being used and how we can improve them to enhance your experience.</li>
        </ul>
        <h3>Use of Your Data</h3>
        <p>
          We use the collected data for various purposes:
        </p>
        <ul>
          <li>To maintain and provide the features and functionality of our services, ensuring they operate effectively and meet your needs.</li>
          <li>To notify you about changes to our services including upgrades, security updates, and changes in policy.</li>
          <li>To allow you to participate in interactive features of our services when you choose to do so.</li>
          <li>To provide customer care and support, addressing your inquiries and issues promptly.</li>
          <li>To provide analysis or valuable information so that we can improve our services.</li>
          <li>To monitor the usage of our services to detect, prevent and address technical issues.</li>
        </ul>
        <h3>Data Protection Rights</h3>
        <p>
          As a valued user, you are afforded specific rights under the GDPR/DSGVO, which include:
        </p>
        <ul>
          <li>Right of Access: You can request copies of your personal data.</li>
          <li>Right to Rectification: You can request that we correct any information you believe is inaccurate or incomplete.</li>
          <li>Right to Erasure: You can request the erasure of your personal data under certain conditions.</li>
          <li>Right to Restrict Processing: You can request that we restrict the processing of your personal data, under certain conditions.</li>
          <li>Right to Data Portability: You can request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</li>
          <li>Right to Object: You have the right to object to our processing of your personal data, under certain conditions.</li>
        </ul>
        <h3>Cookies and Tracking Technologies</h3>
        <p>
          We use cookies and similar tracking technologies to track activity on our services and hold certain information. Cookies are files with a small amount of data which may include an anonymous unique identifier. These are sent to your browser from a website and stored on your device. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device.
        </p>
        <h3>Sharing of Data with Third Parties</h3>
        <p>
          We collaborate with third-party companies and individuals to facilitate our services ("Service Providers"), to provide the services on our behalf, to perform service-related services or to assist us in analyzing how our services are used. Our partners include, but are not limited to, Verein Puls, as well as various vendors, universities, and other entities engaged in public health and safety initiatives.
        </p>
        <h3>Public Information and Third Party Websites</h3>
        <p>
          We may make certain personal data available to strategic partners that work with us to provide our services or that help us market to customers. Personal data will only be shared by us with these companies in order to provide or improve our products, services, and advertising; it will not be shared with third parties for their marketing purposes.
        </p>
        <h3>Security Measures</h3>
        <p>
          The security of your data is paramount to us. We use administrative, technical, and physical security measures to protect your personal data from unauthorized access and disclosure. While we strive to use commercially acceptable means to protect your personal data, no method of transmission over the Internet, or method of electronic storage is 100% secure.
        </p>
        <h3>Data Retention and Deletion Policy</h3>
        <p>
          We retain your personal data only for as long as necessary to fulfill the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements. When we no longer need your personal data, we will securely delete or anonymize it in accordance with our data retention and deletion policies.
        </p>
        <h3>Changes to This Policy</h3>
        <p>
          We may update our Data Protection Regulation from time to time. We will notify you of any changes by posting the new policy on this page. We will let you know via email and/or a prominent notice on our service, prior to the change becoming effective and update the "effective date" at the top of this policy.
        </p>
        <h3>Contact Information</h3>
        <p>
          For any questions or concerns regarding your privacy, please contact us at <a href="mailto:office@kiss-it.io">office@kiss-it.io</a>. We are committed to resolving any privacy concerns you may have.
        </p>

        <h2>Datenschutzbestimmungen</h2>
        <p>
          Willkommen bei KISS IT Solutions e.U., wo Ihre Privatsphäre und Datensicherheit unsere höchste Priorität haben. Diese Datenschutzverordnung legt unsere Praktiken und Verpflichtungen bei der Erfassung, Verwendung und Sicherung Ihrer persönlichen Daten unter den strengen Standards der Allgemeinen Datenschutzverordnung (GDPR/DSGVO) dar. Unser Engagement geht über die Einhaltung hinaus und zielt darauf ab, Transparenz und Vertrauen bei allen unseren Benutzern zu fördern.
        </p>
        <h3>Informationen, die wir sammeln</h3>
        <p>
          Bei KISS IT Solutions e.U. sammeln wir verschiedene Arten von Daten, um unsere Dienste effektiv bereitzustellen und zu verbessern. Dazu gehören:
        </p>
        <ul>
          <li>Persönliche Daten: Informationen, die zur Kontaktaufnahme oder Identifizierung verwendet werden können. Wir sammeln persönliche Daten auf klare und transparente Weise und verwenden diese Daten ausschließlich für die in diesem Dokument dargelegten Zwecke.</li>
          <li>Nutzungsdaten: Diese umfassen Daten, die automatisch entweder durch die Nutzung des Dienstes oder von der Infrastruktur des Dienstes selbst generiert werden (zum Beispiel die Dauer eines Seitenbesuchs). Sie helfen uns zu verstehen, wie unsere Dienste genutzt werden und wie wir sie verbessern können, um Ihre Erfahrung zu verbessern.</li>
        </ul>
        <h3>Verwendung Ihrer Daten</h3>
        <p>
          Wir verwenden die gesammelten Daten für verschiedene Zwecke:
        </p>
        <ul>
          <li>Um die Funktionen und Funktionalitäten unserer Dienste aufrechtzuerhalten und bereitzustellen, damit sie effektiv funktionieren und Ihren Bedürfnissen entsprechen.</li>
          <li>Um Sie über Änderungen an unseren Diensten einschließlich Upgrades, Sicherheitsupdates und Richtlinienänderungen zu informieren.</li>
          <li>Um Ihnen die Teilnahme an interaktiven Funktionen unserer Dienste zu ermöglichen, wenn Sie dies wählen.</li>
          <li>Um Kundenbetreuung und Support zu bieten, Ihre Anfragen und Probleme umgehend zu adressieren.</li>
          <li>Um Analysen oder wertvolle Informationen bereitzustellen, damit wir unsere Dienste verbessern können.</li>
          <li>Um die Nutzung unserer Dienste zu überwachen, um technische Probleme zu erkennen, zu verhindern und zu beheben.</li>
        </ul>
        <h3>Datenschutzrechte</h3>
        <p>
          Als geschätzter Benutzer stehen Ihnen bestimmte Rechte unter der GDPR/DSGVO zu, die unter anderem umfassen:
        </p>
        <ul>
          <li>Zugangsrecht: Sie können Kopien Ihrer persönlichen Daten anfordern.</li>
          <li>Recht auf Berichtigung: Sie können die Korrektur von Informationen anfordern, von denen Sie glauben, dass sie ungenau oder unvollständig sind.</li>
          <li>Recht auf Löschung: Sie können die Löschung Ihrer persönlichen Daten unter bestimmten Bedingungen anfordern.</li>
          <li>Recht auf Einschränkung der Verarbeitung: Sie können anfordern, dass wir die Verarbeitung Ihrer persönlichen Daten unter bestimmten Bedingungen einschränken.</li>
          <li>Recht auf Datenübertragbarkeit: Sie können anfordern, dass wir die Daten, die wir gesammelt haben, an eine andere Organisation oder direkt an Sie übertragen, unter bestimmten Bedingungen.</li>
          <li>Widerspruchsrecht: Sie haben das Recht, der Verarbeitung Ihrer persönlichen Daten unter bestimmten Bedingungen zu widersprechen.</li>
        </ul>
        <h3>Cookies und Tracking-Technologien</h3>
        <p>
          Wir verwenden Cookies und ähnliche Tracking-Technologien, um die Aktivität auf unseren Diensten zu verfolgen und bestimmte Informationen zu speichern. Cookies sind Dateien mit einer kleinen Datenmenge, die möglicherweise eine anonyme eindeutige Kennung enthalten. Diese werden von einer Webseite an Ihren Browser gesendet und auf Ihrem Gerät gespeichert. Sie haben die Möglichkeit, diese Cookies zu akzeptieren oder abzulehnen und zu erfahren, wann ein Cookie an Ihr Gerät gesendet wird.
        </p>
        <h3>Weitergabe von Daten an Dritte</h3>
        <p>
          Wir arbeiten mit Drittanbietern und Einzelpersonen zusammen, um unsere Dienste zu erleichtern ("Dienstanbieter"), um die Dienste in unserem Auftrag zu erbringen, Dienstleistungen im Zusammenhang mit dem Dienst auszuführen oder uns bei der Analyse der Nutzung unserer Dienste zu unterstützen. Unsere Partner umfassen, sind aber nicht beschränkt auf, Verein Puls sowie verschiedene Anbieter, Universitäten und andere Einrichtungen, die sich mit öffentlicher Gesundheit und Sicherheit befassen.
        </p>
        <h3>Öffentliche Informationen und Websites Dritter</h3>
        <p>
          Wir können bestimmte persönliche Daten für strategische Partner verfügbar machen, die mit uns zusammenarbeiten, um unsere Dienste bereitzustellen oder die uns helfen, Kunden zu vermarkten. Persönliche Daten werden von uns nur mit diesen Unternehmen geteilt, um unsere Produkte, Dienstleistungen und Werbung bereitzustellen oder zu verbessern; sie werden nicht mit Dritten für deren Marketingzwecke geteilt.
        </p>
        <h3>Sicherheitsmaßnahmen</h3>
        <p>
          Die Sicherheit Ihrer Daten hat für uns höchste Priorität. Wir verwenden administrative, technische und physische Sicherheitsmaßnahmen, um Ihre persönlichen Daten vor unbefugtem Zugriff und Offenlegung zu schützen. Während wir bestrebt sind, kommerziell akzeptable Mittel zum Schutz Ihrer persönlichen Daten zu verwenden, ist keine Methode der Übertragung über das Internet oder Methode der elektronischen Speicherung 100 % sicher.
        </p>
        <h3>Datenaufbewahrungs- und Löschrichtlinien</h3>
        <p>
          Wir bewahren Ihre persönlichen Daten nur so lange auf, wie es notwendig ist, um die Zwecke zu erfüllen, für die wir sie gesammelt haben, einschließlich der Erfüllung gesetzlicher, buchhalterischer oder berichtspflichtiger Anforderungen. Wenn wir Ihre persönlichen Daten nicht mehr benötigen, werden wir sie gemäß unseren Datenaufbewahrungs- und Löschrichtlinien sicher löschen oder anonymisieren.
        </p>
        <h3>Änderungen dieser Richtlinie</h3>
        <p>
          Wir können unsere Datenschutzbestimmungen von Zeit zu Zeit aktualisieren. Wir werden Sie über Änderungen informieren, indem wir die neue Richtlinie auf dieser Seite veröffentlichen. Wir werden Sie per E-Mail und/oder eine prominente Benachrichtigung auf unserem Service informieren, bevor die Änderung wirksam wird und das "Wirksamkeitsdatum" am Anfang dieser Richtlinie aktualisieren.
        </p>
        <h3>Kontaktinformationen</h3>
        <p>
          Für Fragen oder Bedenken bezüglich Ihrer Privatsphäre kontaktieren Sie uns bitte unter <a href="mailto:office@kiss-it.io">office@kiss-it.io</a>. Wir sind verpflichtet, jegliche Datenschutzbedenken, die Sie haben könnten, zu klären.
        </p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
