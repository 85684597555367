import React, { useContext, useEffect, useState } from 'react';
import { apiUrl } from '../App';
import { MapCenterContext } from './MapContext';
import EventList from './EventList'; // Assuming EventList is correctly imported

interface Alarm {
  id: number;
  name: string;
  locationLatitude: number;
  locationLongitude: number;
}

const AlarmsComponent: React.FC = () => {
  const [alarms, setAlarms] = useState<Alarm[]>([]);
  const { setMapCenter } = useContext(MapCenterContext);
  const [expandedAlarmId, setExpandedAlarmId] = useState<number | null>(null);

  useEffect(() => {
    fetchAlarms();
    const interval = setInterval(fetchAlarms, 10000);
    return () => clearInterval(interval);
  }, []);

  function fetchAlarms() {
    fetch(`${apiUrl}/Defis/GetAlarmList`)
      .then(response => response.json())
      .then((data: Alarm[]) => setAlarms(data))
      .catch(error => console.error('Error:', error));
  }

  function removeAlarm(id: number) {
    fetch(`${apiUrl}/Defis/ClearAlarm?idLocation=${id}`, { method: 'GET' })
      .then(response => {
        if (response.ok) {
          setAlarms(prev => prev.filter(alarm => alarm.id !== id));
        } else {
          console.error('Failed to clear alarm');
        }
      })
      .catch(error => console.error('Error:', error));
  }

  function toggleDetails(id: number, event: React.MouseEvent) {
    event.stopPropagation();
    if (expandedAlarmId === id) {
      setExpandedAlarmId(null); // Close if already open
    } else {
      setExpandedAlarmId(id); // Expand the clicked alarm
    }
  }

  function handleAlarmClick(lat: number, lng: number) {
    setMapCenter([lat, lng]);
  }

  return (
    <div>
      {alarms.map(alarm => (
        <div 
          key={alarm.id}
          style={{ backgroundColor: 'red', padding: '2px', marginBottom: '5px' }}
        >
          <div 
            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            onClick={() => handleAlarmClick(alarm.locationLatitude, alarm.locationLongitude)}
          >
            <span style={{ color: 'white', fontWeight: 'bold', flex: 1 }}>ALARM - {alarm.name}</span>
            <button
              style={{ backgroundColor: 'transparent', border: 'none', color: 'white' }}
              onClick={(e) => {
                toggleDetails(alarm.id, e);
              }}
            >
              {expandedAlarmId === alarm.id ? 'Hide Events' : 'Show Events'}
            </button>
            <button
              style={{ backgroundColor: 'transparent', border: 'none', color: 'white' }}
              onClick={(e) => {
                e.stopPropagation();
                removeAlarm(alarm.id);
              }}
            >
              X
            </button>
          </div>
          {expandedAlarmId === alarm.id && (
            <div style={{ backgroundColor: 'white', padding: '10px' }}>
              <EventList idLocation={alarm.id} />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default AlarmsComponent;
