  import React, { createContext, useContext, useState } from 'react';

  export type DefiWizardContextProps = {
    userAccessLevel: number;
    setUserAccessLevel: React.Dispatch<React.SetStateAction<number>>;
    idLocation: number;
    setIdLocation: React.Dispatch<React.SetStateAction<number>>;
    idAddress: number;
    setIdAddress: React.Dispatch<React.SetStateAction<number>>;
    idContact: number;
    setIdContact: React.Dispatch<React.SetStateAction<number>>;
    idOperator: number;
    setIdOperator: React.Dispatch<React.SetStateAction<number>>;
    idVendor: number;
    setIdVendor: React.Dispatch<React.SetStateAction<number>>;
    idDefibrillatorTracker: number;
    setIdDefibrillatorTracker: React.Dispatch<React.SetStateAction<number>>;
    idCabinetSensor: number;
    setIdCabinetSensor: React.Dispatch<React.SetStateAction<number>>;
    idDefibrillator: number;
    setIdDefibrillator: React.Dispatch<React.SetStateAction<number>>;
    updateIdByDeviceType: (deviceType: number, id: number) => void;
  };

  const DefiWizardContext = createContext<DefiWizardContextProps | null>(null);

  type DefiWizardProviderProps = {
    children: React.ReactNode;
  };

  export const DefiWizardProvider: React.FC<DefiWizardProviderProps> = ({ children }) => {
    const [userAccessLevel, setUserAccessLevel] = useState<number>(0);
    const [idLocation, setIdLocation] = useState<number>(0);
    const [idAddress, setIdAddress] = useState<number>(0);
    const [idContact, setIdContact] = useState<number>(0);
    const [idOperator, setIdOperator] = useState<number>(0);
    const [idVendor, setIdVendor] = useState<number>(0);
    const [idDefibrillatorTracker, setIdDefibrillatorTracker] = useState<number>(0);
    const [idCabinetSensor, setIdCabinetSensor] = useState<number>(0);
    const [idDefibrillator, setIdDefibrillator] = useState<number>(0);

    const updateIdByDeviceType = (deviceType: number, id: number) => {
      switch (deviceType) {
        case 1:
          setIdDefibrillatorTracker(id);
          break;
        case 3:
          setIdCabinetSensor(id);
          break;
        case 4:
          setIdDefibrillator(id);
          break;
        default:
          throw new Error(`Invalid deviceType: ${deviceType}`);
      }
    };

    const value: DefiWizardContextProps = {
      userAccessLevel,
      setUserAccessLevel,
      idLocation,
      setIdLocation,
      idAddress,
      setIdAddress,
      idContact,
      setIdContact,
      idOperator,
      setIdOperator,
      idVendor,
      setIdVendor,
      idDefibrillatorTracker,
      setIdDefibrillatorTracker,
      idCabinetSensor,
      setIdCabinetSensor,
      idDefibrillator,
      setIdDefibrillator,
      updateIdByDeviceType,
    };

    return (
      <DefiWizardContext.Provider value={value}>
        {children}
      </DefiWizardContext.Provider>
    );
  };

  export const useDefiWizardContext = (): DefiWizardContextProps => {
    const context = useContext(DefiWizardContext);
    if (!context) {
      throw new Error('useDefiWizardContext must be used within a DefiWizardProvider');
    }
    return context;
  };

  // Default export for the context
  export default DefiWizardContext;
