import React, { ChangeEvent, useEffect, useState } from 'react';
import { apiUrl } from '../App';
import Survey from './Survey';  // Adjust the import path as needed

interface Location {
  id: number;
  name?: string;
  operatorName?: string;
  vendorName?: string;
  street?: string;
  houseNumber?: string;
  city?: string;
  postalCode?: string;
}

interface DefiCheckFieldProps {
  phoneNumber: string;
  locationEnabled: boolean;
}

const DefiCheckField: React.FC<DefiCheckFieldProps> = ({ phoneNumber: initialPhoneNumber, locationEnabled: initialLocationEnabled }) => {
  const [currentStep, setCurrentStep] = useState<'requestCode' | 'verifyCode' | 'locationPermission' | 'enterAddress' | 'confirmLocation' | 'uploadPhotos' | 'submitted'>('requestCode');
  const [phoneNumber, setPhoneNumber] = useState(initialPhoneNumber.replace(/^\+43/, '') || '');  // Ensure initial phone number doesn't duplicate country code
  const [verificationCode, setVerificationCode] = useState('');
  const [statusMessage, setStatusMessage] = useState('Enter your phone number (intl. format e.g. +436641234567)');
  const [sessionId, setSessionId] = useState(localStorage.getItem('sessionId') || '');
  const [location, setLocation] = useState({ lat: '', lon: '' });
  const [address, setAddress] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  const countryCode = '+43';

  const style: { [key: string]: React.CSSProperties } = {
    container: {
      padding: '20px',
      fontFamily: 'Arial',
      display: 'flex',
      flexDirection: 'column',
      minHeight: '66vh',  // Ensure the container takes at least the height of the viewport
      alignItems: 'center',  // Center children horizontally
      justifyContent: 'center'  // Center children vertically
    },
    questionBlock: {
      width: '100%',
      maxWidth: '500px',
      marginBottom: '30px',
      textAlign: 'center',
      border: '1px solid #ddd',
      borderRadius: '8px',
      padding: '20px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    image: {
      width: '100%',
      maxWidth: '300px',
      marginBottom: '20px'
    },
    input: {
      margin: '10px 0',
      padding: '10px',
      width: '100%',
      maxWidth: '300px',
      boxSizing: 'border-box'
    },
    button: {
      padding: '10px 20px',
      margin: '10px',
      cursor: 'pointer',
      width: '100%',
      maxWidth: '200px',
      boxSizing: 'border-box'
    },
    label: { display: 'block', marginTop: '20px' },
    inputGroup: {
      display: 'flex',
      alignItems: 'center'
    },
    countryCode: {
      padding: '10px',
      margin: '10px 0 10px 10px',
      border: '1px solid #ccc',
      backgroundColor: '#eee',
      textAlign: 'center',
      width: '50px',
      userSelect: 'none',  // Prevent text selection
      pointerEvents: 'none',  // Disable interactions
      color: '#333'
    },
    authInfoContainer: {
      marginTop: '20px',
      textAlign: 'center',
      fontSize: '14px',
      color: '#666'
    },
    resetButton: {
      padding: '5px 10px',
      marginLeft: '10px',
      cursor: 'pointer',
      backgroundColor: '#bbb',
      color: 'white',
      border: 'none',
      borderRadius: '5px'
    }
  };

  useEffect(() => {
    if (sessionId) {
      if (initialLocationEnabled) {
        handleLocationPermission(true);
      } else {
        setCurrentStep('locationPermission');
        setStatusMessage('Logged in successfully! Would you like to use your current location or enter address manually?');
      }
    } else if (initialPhoneNumber) {
      setPhoneNumber(initialPhoneNumber.replace(/^\+43/, ''));
      setCurrentStep('verifyCode');
      setStatusMessage('Please enter the code you received.');
    }
  }, [initialPhoneNumber, initialLocationEnabled, sessionId]);

  const reverseGeocode = async (lat: number, lon: number): Promise<string> => {
    const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}`;
    try {
      const response = await fetch(url, {
        headers: {
          'User-Agent': 'YourApp/1.0'  // Specify your app's name/version
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch address.');
      }
      const data = await response.json();
      return data.display_name || 'Address not found';  // 'display_name' contains the full address
    } catch (error) {
      console.error('Error during reverse geocoding:', error);
      return 'Failed to retrieve address';
    }
  };

  const geocode = async (address: string): Promise<{ lat: string, lon: string }> => {
    const url = `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(address)}`;
    try {
      const response = await fetch(url, {
        headers: {
          'User-Agent': 'YourApp/1.0'
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch coordinates.');
      }
      const data = await response.json();
      if (data.length > 0) {
        const { lat, lon } = data[0];
        return { lat, lon };  // Return the first result's latitude and longitude
      } else {
        throw new Error('No results found');
      }
    } catch (error) {
      console.error('Error during geocoding:', error);
      return { lat: '0', lon: '0' };  // Return a default value or handle as needed
    }
  };

  const handleRequestCode = async () => {
    setStatusMessage('Sending code...');
    try {
      const fullPhoneNumber = countryCode + phoneNumber;
      const response = await fetch(`${apiUrl}/Defis/RequestAuthCode`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ Mobile: fullPhoneNumber })
      });
      if (!response.ok) {
        const errorData = await response.json();  // Assuming the server might return JSON with error details
        throw new Error(errorData.message || 'Request failed with status ' + response.status);
      }
      const data = await response.json();
      setSessionId(data.sessionId);  // Ensure this data.sessionId is correctly provided by the backend
      console.log('Session ID:', data.sessionId);
      setCurrentStep('verifyCode');
      setStatusMessage('Please enter the code you received.');
    } catch (error) {
      setStatusMessage(error instanceof Error ? error.message : 'Failed to send code. Please try again.');
    }
  };

  const handleVerifyCode = async () => {
    setStatusMessage('Verifying code...');
    try {
      const fullPhoneNumber = countryCode + phoneNumber;
      const response = await fetch(`${apiUrl}/Defis/VerifyAuthCode`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ Mobile: fullPhoneNumber, Code: verificationCode })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || `Verification failed with status ${response.status}`);
      }

      const data = await response.json();
      if (data.sessionId) {
        setSessionId(data.sessionId);
        localStorage.setItem('phoneNumber', fullPhoneNumber);
        localStorage.setItem('sessionId', data.sessionId);
        if (initialLocationEnabled) {
          handleLocationPermission(true);
        } else {
          setCurrentStep('locationPermission');
          setStatusMessage('Logged in successfully! Do you agree to share your location?');
        }
      } else {
        throw new Error('Session ID was not returned from the server');
      }
    } catch (error) {
      setStatusMessage(error instanceof Error ? error.message : 'Verification failed. Please try again.');
    }
  };

  const handleLocationPermission = async (permission: boolean): Promise<void> => {
    localStorage.setItem('locationEnabled', String(permission));
    if (permission) {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(async (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ lat: latitude.toString(), lon: longitude.toString() });
          const addr = await reverseGeocode(latitude, longitude);
          setAddress(addr);
          setCurrentStep('confirmLocation');
          //setStatusMessage(`Found location: ${addr}. Confirm or use different address.`);
          setStatusMessage(`Confirm or use different address.`);
          resolve();
        }, (error) => {
          console.error('Geolocation error:', error);
          if (error.code === error.PERMISSION_DENIED) {
            setStatusMessage('Location access denied. Please enter address manually or enable location permissions in your settings.');
          } else {
            setStatusMessage('Location access failed. Please enter your address.');
          }
          setCurrentStep('enterAddress');
          reject(new Error('Location permission denied'));
        });
      });
    } else {
      setStatusMessage('Please enter your address manually.');
      setCurrentStep('enterAddress');
      return Promise.resolve();
    }
  };

  const handleLocationConfirmation = async (confirm: boolean): Promise<void> => {
    if (confirm) {
      setCurrentStep('uploadPhotos');
      setStatusMessage('Please upload photos and answer the questions.');
    } else {
      setCurrentStep('enterAddress');
    }
    return Promise.resolve();
  };

  const handleAddressSubmit = async () => {
    const coords = await geocode(address);
    setLocation({ lat: coords.lat, lon: coords.lon });
    setCurrentStep('confirmLocation');
    setStatusMessage(`Please confirm the closest location for address: ${address} or enter a different one.`);
  };

  const handleResetAuth = () => {
    localStorage.removeItem('phoneNumber');
    localStorage.removeItem('sessionId');
    localStorage.removeItem('locationEnabled');
    setSessionId('');
    setPhoneNumber('');
    setCurrentStep('requestCode');
    setStatusMessage('Enter your phone number (intl. format e.g. +436641234567)');
  };

  return (
    <div style={style.container}>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        {currentStep === 'requestCode' && (
          <div style={style.questionBlock}>
            <label>Enter your phone number (intl. format e.g. +436641234567):</label>
            <div style={{...style.inputGroup, justifyContent: 'center'}}>
              <div style={style.countryCode}>{countryCode}</div>
              <input
                type="tel"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value.replace(/^\+43/, ''))}  // Ensure country code isn't duplicated
                placeholder="Phone Number"
                style={{ ...style.input, maxWidth: '250px' }}
              />
            </div>
            <button 
              onClick={() => {
                setIsProcessing(true);
                handleRequestCode().finally(() => setIsProcessing(false));
              }}
              disabled={isProcessing}
              style={style.button}
            >
              Send Code
            </button>
          </div>
        )}
        {currentStep === 'verifyCode' && (
          <div style={style.questionBlock}>
            <label>Please enter the code you received:</label>
            <input
              type="tel"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              placeholder="Verification Code"
              style={style.input}
            />
            <button 
              onClick={() => {
                setIsProcessing(true);
                handleVerifyCode().finally(() => setIsProcessing(false));
              }}
              disabled={isProcessing}
              style={style.button}
            >
              Verify Code
            </button>
          </div>
        )}
        {currentStep === 'locationPermission' && (
          <div style={style.questionBlock}>
            <label>Logged in successfully! Would you like to use your current location or enter address manually?</label>
            <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
              <button 
                onClick={() => {
                  setIsProcessing(true);
                  handleLocationPermission(true).finally(() => setIsProcessing(false));
                }}
                disabled={isProcessing}
                style={style.button}
              >
                Yes, Use Current Location
              </button>
              <button 
                onClick={() => {
                  setIsProcessing(true);
                  handleLocationPermission(false).finally(() => setIsProcessing(false));
                }}
                disabled={isProcessing}
                style={style.button}
              >
                No, Enter Address
              </button>
            </div>
          </div>
        )}
        {currentStep === 'enterAddress' && (
          <div style={style.questionBlock}>
            <label>Please enter your address:</label>
            <input
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              placeholder="Address"
              style={style.input}
            />
            <button 
              onClick={() => {
                setIsProcessing(true);
                handleAddressSubmit().finally(() => setIsProcessing(false));
              }}
              disabled={isProcessing}
              style={style.button}
            >
              Submit Address
            </button>
          </div>
        )}
        {currentStep === 'confirmLocation' && (
          <div style={style.questionBlock}>
            <label>Is this the correct location?</label>
            <p>{address} - Coordinates: {location.lat} / {location.lon}</p>
            <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
              <button 
                onClick={() => {
                  setIsProcessing(true);
                  handleLocationConfirmation(true).finally(() => setIsProcessing(false));
                }}
                disabled={isProcessing}
                style={style.button}
              >
                Confirm
              </button>
              <button 
                onClick={() => {
                  setIsProcessing(true);
                  handleLocationConfirmation(false).finally(() => setIsProcessing(false));
                }}
                disabled={isProcessing}
                style={style.button}
              >
                Use Different Address
              </button>
            </div>
          </div>
        )}
        {currentStep === 'uploadPhotos' && (
          <Survey
            sessionId={sessionId}
            address={address}
            location={location}
            setCurrentStep={setCurrentStep}
            setStatusMessage={setStatusMessage}
          />
        )}
        {currentStep === 'submitted' && (
          <p>Thank you for submitting the survey!</p>
        )}
      </div>
      <p style={{ marginTop: '20px', textAlign: 'center', width: '100%' }}>
        {statusMessage}
      </p>
      <div style={style.authInfoContainer}>
        <span>Auth Info: {sessionId ? `Authenticated with ${phoneNumber.startsWith('+43') ? phoneNumber : countryCode + phoneNumber}` : 'Not authenticated'}</span>
        <button style={style.resetButton} onClick={handleResetAuth}>Reset Auth Info</button>
      </div>
    </div>
  );
};

export default DefiCheckField;
