import React from 'react';
import { useParams } from 'react-router-dom';

const LostAndFound: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <div className="lostandfound-container">
      <div className="header">

        <h1>Lost and Found</h1>
      </div>

      <div className="item-details">
        <h2>Item ID: {id}</h2>
        <p>Contact us at: <a href="mailto:office@kiss-it.io">office@kiss-it.io</a></p>
        <p>Or call: +4367763700444</p>
      </div>

      
    </div>
  );
}

export default LostAndFound;
