import React, { useState, useEffect, useContext } from 'react';
import DefiWizardContext, { DefiWizardContextProps } from './DefiWizardContext';

interface Operator {
  id: number;
  name: string;
}

const OperatorList: React.FC = () => { 
  
  const [operatorList, setOperatorList] = useState<Operator[]>([]);
  const [selectedOperatorId, setSelectedOperatorId] = useState<number | 'new'>('new');
  const [newOperator, setNewOperator] = useState<Operator>({
    id: 0,
    name: '',
  });

  useEffect(() => {
    async function fetchOperators() {
      try {
        const response = await fetch('/api/Defis/GetOperatorList');  // Adjust the API endpoint if necessary
        if (!response.ok) {
          throw new Error(`Error fetching operators: ${response.statusText}`);
        }
        const dataResponse = await response.json();
        if (dataResponse.status === "success") {
          const data: Operator[] = dataResponse.jsn;
          setOperatorList(data);
        } else {
          console.error('Error fetching operators: Response status is not success');
        }
      } catch (error) {
        console.error('Error fetching operators:', error);
      }
    }
    fetchOperators();
  }, []);

  useEffect(() => {
    if (idOperator != 0) {
      setSelectedOperatorId(idOperator);
    }
  }, []);

  useEffect(() => {
    if (selectedOperatorId === 'new') {
      setNewOperator({
        id: 0,
        name: '',
      });
    } else {
      const foundOperator = operatorList.find(op => op.id === selectedOperatorId);
      if (foundOperator) setNewOperator(foundOperator);
    }
  }, [selectedOperatorId, operatorList]);

  const context = useContext(DefiWizardContext);
  if (!context) {
    return null; 
  }
  const { idOperator, setIdOperator, userAccessLevel } = context;
  const isDisabled = userAccessLevel === 0; 

  const handleNewOperatorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewOperator((prevOperator) => ({
      ...prevOperator,
      [event.target.name]: event.target.value,
    }));
  };

  const handleDropdownChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setSelectedOperatorId(value === 'new' ? 'new' : parseInt(value));
  };

  const handleSaveOperator = async () => {
    try {
      const response = await fetch('/api/Defis/UpsertOperator', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newOperator),
      });
  
      if (!response.ok) {
        throw new Error(`Error saving operator: ${response.statusText}`);
      }
  
      const serverResponse = await response.json();
      const savedOperator: Operator = serverResponse.jsn;  // Assuming the saved object is in 'jsn' field
      console.log("Saved operator:", savedOperator); // Log the saved operator
  
      // Check if the operator already exists in the list
      const index = operatorList.findIndex(op => op.id === savedOperator.id);
  
      if (index !== -1) {
        // Update existing operator in the list
        setOperatorList((prevList) => {
          const updatedList = [...prevList];
          updatedList[index] = savedOperator;
          console.log("Updated Operator List:", updatedList); // Log the updated list
          return updatedList;
        });
      } else {
        // Add new operator to the list
        setOperatorList((prevList) => {
          const updatedList = [...prevList, savedOperator];
          console.log("Updated Operator List:", updatedList); // Log the updated list
          return updatedList;
        });
      }
  
      // Select the newly saved operator
      console.log("Setting selected operator ID:", savedOperator.id); // Log selected operator ID
      setSelectedOperatorId(savedOperator.id);
      setIdOperator(savedOperator.id);
  
    } catch (error) {
      console.error('Error saving operator:', error);
    }
  };
  

  return (
    <div className="list-container">
      <h3 className="list-header">Operator</h3>
      <select 
        value={selectedOperatorId} 
        onChange={handleDropdownChange} 
        className="list-select"
        disabled={isDisabled}>
        <option value="new">Create New</option>
        {operatorList.map((operator) => (
          <option key={operator.id} value={operator.id}>
            {operator.name}
          </option>
        ))}
        
      </select>
  
      <h3 className="list-header">
        {selectedOperatorId === 'new' ? 'New Operator' : 'Edit Operator'}
      </h3>
      <input
        type="text"
        name="name"
        placeholder="Operator Name"
        value={newOperator.name}
        onChange={handleNewOperatorChange}
        className="list-input"
        disabled={isDisabled}
      />
      <button 
        onClick={handleSaveOperator} 
        className={isDisabled ? "list-input-button-disabled" : "list-input-button"} disabled={isDisabled}>
        {selectedOperatorId === 'new' ? 'Save New Operator' : 'Update Operator'}
      </button>
    </div>
  );
  
};

export default OperatorList;
