import React, { useState, useEffect, useContext } from 'react';
import DefiWizardContext, { DefiWizardContextProps } from './DefiWizardContext';

interface Contact {
  id: number;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  mobile: string | null;
  comment: string | null;
}

const ContactList: React.FC = () => { 
  
  const [contactList, setContactList] = useState<Contact[]>([]);
  const [selectedContactId, setSelectedContactId] = useState<number | 'new'>('new');
  const [newContact, setNewContact] = useState<Contact>({
    id: 0,
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    comment: '',
  });

  useEffect(() => {
    async function fetchContacts() {
      try {
        const response = await fetch('/api/Defis/GetContactList');  // Adjust the API endpoint if necessary
        if (!response.ok) {
          throw new Error(`Error fetching contacts: ${response.statusText}`);
        }
        const dataResponse = await response.json();
        if (dataResponse.status === "success") {
          const data: Contact[] = dataResponse.jsn;
          setContactList(data);
        } else {
          console.error('Error fetching contacts: Response status is not success');
        }
      } catch (error) {
        console.error('Error fetching contacts:', error);
      }
    }
    fetchContacts();
  }, []);

  useEffect(() => {
    if (idContact != 0) {
      setSelectedContactId(idContact);
    }
  }, []);

  useEffect(() => {
    if (selectedContactId === 'new') {
      setNewContact({
        id: 0,
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        comment: '',
      });
    } else {
      const foundContact = contactList.find(c => c.id === selectedContactId);
      if (foundContact) setNewContact(foundContact);
    }
  }, [selectedContactId, contactList]);

  const context = useContext(DefiWizardContext);
  if (!context) {
    return null; 
  }
  const { idContact, setIdContact, userAccessLevel } = context;
  const isDisabled = userAccessLevel === 0; 

  const handleNewContactChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setNewContact((prevContact) => ({
      ...prevContact,
      [event.target.name]: event.target.value,
    }));
  };

  const handleDropdownChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setSelectedContactId(value === 'new' ? 'new' : parseInt(value));
  };

  const handleSaveContact = async () => {
    try {
      const response = await fetch('/api/Defis/UpsertContact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newContact),
      });
  
      if (!response.ok) {
        throw new Error(`Error saving contact: ${response.statusText}`);
      }
  
      const serverResponse = await response.json();
      const savedContact: Contact = serverResponse.jsn; // Assuming the server responds with the object in the 'jsn' field
      console.log("Saved contact:", savedContact); // Log the saved contact
  
      // Check if the contact already exists in the list
      const index = contactList.findIndex(c => c.id === savedContact.id);
  
      if (index !== -1) {
        // Update existing contact in the list
        setContactList((prevList) => {
          const updatedList = [...prevList];
          updatedList[index] = savedContact;
          console.log("Updated Contact List:", updatedList); // Log the updated list
          return updatedList;
        });
      } else {
        // Add new contact to the list
        setContactList((prevList) => {
          const updatedList = [...prevList, savedContact];
          console.log("Updated Contact List:", updatedList); // Log the updated list
          return updatedList;
        });
      }
  
      // Select the newly saved contact
      console.log("Setting selected contact ID:", savedContact.id); // Log selected contact ID
      setSelectedContactId(savedContact.id);
      setIdContact(savedContact.id);
  
    } catch (error) {
      console.error('Error saving contact:', error);
    }
  };
  

  return (
    <div className="list-container">
      <h3 className="list-header">Contact</h3>
      <select value={selectedContactId} onChange={handleDropdownChange} className="list-select" disabled={isDisabled}>
        <option value="new">Create New</option>
        {contactList.map((contact) => (
          <option key={contact.id} value={contact.id}>
            {contact.firstName} {contact.lastName}
          </option>
        ))}
      </select>
  
      <h3 className="list-header">{selectedContactId === 'new' ? 'New Contact' : 'Edit Contact'}</h3>
      <input
        type="text"
        name="firstName"
        placeholder="First Name"
        value={newContact.firstName || ''}
        onChange={handleNewContactChange}
        className="list-input"
        disabled={isDisabled}
      />
      <input
        type="text"
        name="lastName"
        placeholder="Last Name"
        value={newContact.lastName || ''}
        onChange={handleNewContactChange}
        className="list-input"
        disabled={isDisabled}
      />
      <input
        type="email"
        name="email"
        placeholder="Email"
        value={newContact.email || ''}
        onChange={handleNewContactChange}
        className="list-input"
        disabled={isDisabled}
      />
      <input
        type="tel"
        name="mobile"
        placeholder="Mobile Number"
        value={newContact.mobile || ''}
        onChange={handleNewContactChange}
        className="list-input"
        disabled={isDisabled}
      />
      <textarea
        name="comment"
        placeholder="Comment"
        value={newContact.comment || ''}
        onChange={handleNewContactChange}
        className="list-textarea"
        disabled={isDisabled}
      />
      <button onClick={handleSaveContact} className={isDisabled ? "list-input-button-disabled" : "list-input-button"} disabled={isDisabled}>
        {selectedContactId === 'new' ? 'Save New Contact' : 'Update Contact'}
      </button>
    </div>
  );
  
};

export default ContactList;
