import React, { useState, useEffect } from 'react';
import './AedHistory.css';

interface HistoryItem {
  Type: string;
  Detail: string;
  EditedTs: string;
  Details?: string;
  comment?: string;
  editedByFullName?: string;
}

interface AedHistoryProps {
  idLocation: number;
}

const AedHistory: React.FC<AedHistoryProps> = ({ idLocation }) => {
  const [combinedHistory, setCombinedHistory] = useState<HistoryItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [detailsVisibility, setDetailsVisibility] = useState<{ [key: number]: boolean }>({});

  useEffect(() => {
    const fetchAedHistory = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`/api/Defis/GetAedHistory?idLocation=${idLocation}`);
        const data = await response.json();
        setCombinedHistory(data.jsn);
      } catch (error) {
        console.error('Error fetching AED history:', error);
      }
      setIsLoading(false);
    };
    fetchAedHistory();
  }, [idLocation]);

  const renderDate = (dateString: string) => {
    return dateString ? new Date(dateString).toLocaleString() : 'No timestamp available';
  };

  const toggleDetails = (index: number) => {
    setDetailsVisibility(prev => ({ ...prev, [index]: !prev[index] }));
  };

  const renderItem = (item: HistoryItem, index: number) => {
    let eventLabel;
    switch (item.Type) {
      case "DefibrillatorEvent":
        eventLabel = "AED Event";
        break;
      case "CabinetSensorEvent":
        eventLabel = "Cabinet Event";
        break;
      case "Action":
        eventLabel = "Action";
        break;
      default:
        eventLabel = "Event";
    }

    const showDetailsButton = item.Details && item.Type !== "Action" ? (
      <button onClick={() => toggleDetails(index)}>
        {detailsVisibility[index] ? 'Hide Details' : 'Show Details'}
      </button>
    ) : null;

    return (
      <>
        <p>{eventLabel}: {item.Detail}</p>
        <p>Timestamp: {renderDate(item.EditedTs)}</p>
        {showDetailsButton}
        {detailsVisibility[index] && <p>Details: {item.Details}</p>}
        {item.Type === "Action" && (
          <>
            <p>Comment: {item.comment}</p>
            <p>Edited By: {item.editedByFullName}</p>
          </>
        )}
      </>
    );
  };

  return (
    <div className="aed-history-container">
      <h3>Event History</h3>
      {isLoading ? (
        <div className="spinner"></div>
      ) : (
        <div>
          {combinedHistory.length === 0 ? (
            <p>No history available.</p>
          ) : (
            <ul className="aed-history-list">
              {combinedHistory.map((item, index) => (
                <li key={index}>
                  {renderItem(item, index)}
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  );
};

export default AedHistory;
