import React, { useState, useEffect } from 'react';
import { apiUrl } from '../App';
import Modal from 'react-modal';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import DefiWizard from './DefiWizard';
import DefiWizardContext, { DefiWizardProvider, useDefiWizardContext } from './DefiWizardContext';
import EventList from './EventList';
import AedStatus from './AedStatus';
import AedHistory from './AedHistory';

function formatFieldName(fieldName: string) {
  const formatted = fieldName
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, (str: string) => str.toUpperCase());
  return formatted;
}

function truncate(text: string, maxLength: number) {
  return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
}

Modal.setAppElement('#root');

interface Defi {
  id: number;
  idAddress: number;
  idContact: number;
  idOperator: number;
  idDefibrillatorTracker: number;
  idCabinetSensor: number;
  idDefibrillator: number;
  name: string;
  street: string;
  houseNumber: string;
  postalCode: string;
  city: string;
}

interface DefiAdminProps {
  email: string;
}

const DefiAdmin: React.FC<DefiAdminProps> = ({ email }) => {
  const [defis, setDefis] = useState<Defi[]>([]);
  const [filteredDefis, setFilteredDefis] = useState<Defi[]>([]);
  const [filter, setFilter] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedDefi, setSelectedDefi] = useState<Defi | null>(null);
  const [reloadList, setReloadList] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(apiUrl + '/Defis/GetLocations?email=' + email);
        if (!response.ok) {
          throw new Error(`Error fetching defis: ${response.statusText}`);
        }
        const data: Defi[] = await response.json();
        setDefis(data);
      } catch (error) {
        console.error('Error fetching defis:', error);
      }
    }

    fetchData();
  }, [reloadList, email]);

  useEffect(() => {
    setFilteredDefis(
      defis.filter((defi) =>
        Object.keys(defi)
          .filter((key) => ['id', 'name', 'street', 'houseNumber', 'postalCode', 'city'].includes(key))
          .some(
            (key) =>
              defi[key as keyof Defi]
                ?.toString()
                ?.toLowerCase()
                .includes(filter.toLowerCase())
          )
      )
    );
  }, [defis, filter]);

  const { setIdLocation, setIdAddress, setIdContact, setIdOperator, setIdDefibrillatorTracker, setIdCabinetSensor, setIdDefibrillator } = useDefiWizardContext();

  useEffect(() => {
    if (!modalIsOpen){
      setSelectedDefi(null);
      setReloadList(!reloadList);
    }
  }, [modalIsOpen]);

  useEffect(() => {
    if (selectedDefi) {
      setIdLocation(selectedDefi.id);
      setIdAddress(selectedDefi.idAddress);
      setIdContact(selectedDefi.idContact);
      setIdOperator(selectedDefi.idOperator);
      setIdDefibrillatorTracker(selectedDefi.idDefibrillatorTracker);
      setIdCabinetSensor(selectedDefi.idCabinetSensor);
      setIdDefibrillator(selectedDefi.idDefibrillator);
    } else {
      setIdLocation(0);
      setIdAddress(0);
      setIdContact(0);
      setIdOperator(0);
      setIdDefibrillatorTracker(0);
      setIdCabinetSensor(0);
      setIdDefibrillator(0);
    }
  }, [selectedDefi]);

  const { setUserAccessLevel } = useDefiWizardContext();
  useEffect(() => {
    async function fetchUserAccessLevel() {
      try {
        const response = await fetch(apiUrl + '/Defis/GetUserAccessLevel?email=' + email);
        if (!response.ok) {
          throw new Error(`Error fetching user access level: ${response.statusText}`);
        }
        const data = await response.json();
        setUserAccessLevel(data);
      } catch (error) {
        console.error('Error fetching user access level:', error);
      }
    }

    fetchUserAccessLevel();
  }, [email, setUserAccessLevel]);

  const handleAddNew = () => {
    setSelectedDefi(null);
    setModalIsOpen(true);
  };

  return (
    <div style={{ flex: 1 }}>
      <input
        type="text"
        placeholder="Filter defis"
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
      />
      <button onClick={handleAddNew}>+</button>

      <table>
        <thead>
          <tr>
            <th style={{ textAlign: 'left' }}>Standortkennung</th>
            <th style={{ textAlign: 'left' }}>Strasse</th>
            <th style={{ textAlign: 'left' }}>Hausnummer</th>
            <th style={{ textAlign: 'left' }}>Plz</th>
            <th style={{ textAlign: 'left' }}>Ort</th>
          </tr>
        </thead>
        <tbody>
          {filteredDefis.map((defi) => (
            <tr key={defi.id} onClick={() => { setSelectedDefi(defi); setModalIsOpen(true); }}>
              <td>{defi.name}</td>
              <td>{defi.street}</td>
              <td>{defi.houseNumber}</td>
              <td>{defi.postalCode}</td>
              <td>{defi.city}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {modalIsOpen && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={() => setModalIsOpen(false)}
          contentLabel="Edit Defi"
        >
          <button
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              backgroundColor: '#E40138',
              color: 'white',
              border: 'none',
              borderRadius: '50%',
              width: '30px',
              height: '30px',
              textAlign: 'center',
              fontSize: '24px',
              cursor: 'pointer',
              boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)'
            }}
            onClick={() => setModalIsOpen(false)}
          >
            X
          </button>

          <Tabs>
            <TabList>
              <Tab>Wizard</Tab>
              <Tab>Cabinet Events</Tab>
              <Tab>AED Events</Tab>
            </TabList>

            <TabPanel>
              <h2>{selectedDefi ? `Edit Location (ID: ${selectedDefi.id})` : 'Add New Location'}</h2>
              <DefiWizard />
            </TabPanel>
            <TabPanel>
              <EventList idLocation={selectedDefi?.id || 0} />
            </TabPanel>
            <TabPanel>
              <AedStatus idLocation={selectedDefi?.id || 0} email = {email} />
              <AedHistory idLocation={selectedDefi?.id || 0} />
            </TabPanel>
          </Tabs>
        </Modal>
      )}
    </div>
  );
};

export default DefiAdmin;
