import React, { useState, useEffect, useRef } from 'react';
import L, { LatLngExpression, Icon } from 'leaflet';
import { apiUrl } from '../App';
import redMarkerIcon from '../resources/marker-icon-red.png';

const initialPosition: LatLngExpression = [48.2082, 16.3738];
const defaultZoom = 16;

const formatMinutes = (minutes: number): string => {
  if (minutes >= 60) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours} hour${hours > 1 ? 's' : ''} ${remainingMinutes} minute${remainingMinutes === 1 ? '' : 's'} ago`;
  }
  return `${minutes} minute${minutes === 1 ? '' : 's'} ago`;
};

const DefiFinder: React.FC = () => {
  const [securityCardNumber, setSecurityCardNumber] = useState<string>('');
  const [locations, setLocations] = useState<any[]>([]);
  const [sliderValue, setSliderValue] = useState<number>(0);

  const mapRef = useRef<L.Map | null>(null);
  const markerRef = useRef<L.Marker | null>(null);

  const resetMapAndMarker = () => {
    if (mapRef.current) {
      mapRef.current.setView(initialPosition, defaultZoom);
    }
    if (markerRef.current) {
      markerRef.current.remove();
      markerRef.current = null;
    }
  };

  const updateLocationMarker = (newLocations: any[], newSliderValue: number) => {
    if (mapRef.current && newLocations.length > 0) {
      const targetTimestamp = new Date().getTime() - newSliderValue * 60 * 1000;
  
      let closestLocation = newLocations.reduce((prev: any, curr: any) => {
        if (!prev) return curr;
  
        const prevTimestamp = new Date(prev.valueTs).getTime();
        const currTimestamp = new Date(curr.valueTs).getTime();
  
        const prevDiff = Math.abs(prevTimestamp - targetTimestamp);
        const currDiff = Math.abs(currTimestamp - targetTimestamp);
  
        return currDiff < prevDiff ? curr : prev;
      }, null);
  
      if (closestLocation) {
        const { valueLatitude, valueLongitude, valueTs, editedTs } = closestLocation;
        const newLatLng: LatLngExpression = [valueLatitude, valueLongitude];
  
        console.log("Currently shown Latitude:", valueLatitude);
        console.log("Currently shown Longitude:", valueLongitude);
        console.log("Value Timestamp:", valueTs);
        console.log("Edited Timestamp:", editedTs);
  
        mapRef.current.setView(newLatLng, defaultZoom);
  
        if (markerRef.current) {
          markerRef.current.setLatLng(newLatLng);
        } else {
          const redIcon = new Icon({
            iconUrl: redMarkerIcon,
            iconSize: [25, 41],
            iconAnchor: [12, 41],
          });
          markerRef.current = L.marker(newLatLng, { icon: redIcon }).addTo(mapRef.current);
        }
      }
    }
  }; 

  const fetchLocations = async () => {
    try {
      const response = await fetch(`${apiUrl}/Defis/GetDeviceDataHistory?searchKey=${securityCardNumber}`);
      if (response.status === 500) {
        alert("Card number not found!");
        resetMapAndMarker();
        setLocations([]);
        return;
      }
      const data = await response.json();
      setLocations(data);
      setSliderValue(0);
      updateLocationMarker(data, 0);
    } catch (error) {
      console.error('Error fetching locations:', error);
    }
  };

  useEffect(() => {
    if (!mapRef.current) {
      mapRef.current = L.map('defiFinderMap', {
        center: initialPosition,
        zoom: defaultZoom,
      });

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      }).addTo(mapRef.current);
    }
  }, []);

  useEffect(() => {
    updateLocationMarker(locations, sliderValue);
  }, [locations, sliderValue]);

  return (
    <div>
      <div>
        <input
          type="text"
          value={securityCardNumber}
          onChange={(e) => setSecurityCardNumber(e.target.value)}
          placeholder="Enter PULS KISS Security Card Number"
          style={{ width: '13%' }}
        />
        <button onClick={fetchLocations}>Show</button>
      </div>
      <div style={{ width: '99%' }}>
        <input
          type="range"
          min="0"
          max="2880"
          value={sliderValue}
          onChange={(e) => setSliderValue(Number(e.target.value))}
          style={{ width: '100%' }}
        />
        <span>{formatMinutes(sliderValue)}</span>
      </div>
      <div id="defiFinderMap" style={{ height: '80vh', zIndex: 1 }}></div>
    </div>
  );
};

export default DefiFinder;
