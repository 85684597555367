import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

interface Address {
  postalCode: string;
  city: string;
  street: string;
  houseNumber: string;
  coordinates: {
    lat: string;
    lon: string;
  };
}

interface Contact {
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
}

interface AED {
  manufacturer: string;
  model: string;
  serialNumber: string;
}

const Registration: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const apiUrl = '/api'; // Base URL for your API

  const [locationName, setLocationName] = useState('');
  const [address, setAddress] = useState<Address>({ postalCode: '', city: '', street: '', houseNumber: '', coordinates: { lat: '', lon: '' } });
  const [contact, setContact] = useState<Contact>({ firstName: '', lastName: '', email: '', mobile: '' });
  const [operator, setOperator] = useState('');
  const [sensorCardNumber, setSensorCardNumber] = useState('');
  const [aed, setAED] = useState<AED>({ manufacturer: '', model: '', serialNumber: '' });
  const [aedPhotos, setAEDPhotos] = useState<File[]>([]);
  const [locationPhotos, setLocationPhotos] = useState<File[]>([]);
  const [sensorPhotos, setSensorPhotos] = useState<File[]>([]);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const convertToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = error => reject(error);
    });
  };

  const handlePhotoUpload = (setPhotos: React.Dispatch<React.SetStateAction<File[]>>, multiple: boolean = false) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files) return;
    if (multiple) {
      setPhotos(Array.from(files));
    } else {
      setPhotos([files[0]]);
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const imgJsonPromises = [
      ...aedPhotos.map(file => convertToBase64(file).then(base64 => ({ key: file.name, value: base64 }))),
      ...locationPhotos.map(file => convertToBase64(file).then(base64 => ({ key: file.name, value: base64 }))),
      ...sensorPhotos.map(file => convertToBase64(file).then(base64 => ({ key: file.name, value: base64 })))
    ];

    // Declare imgJson with an appropriate type
    const imgJson: Record<string, string> = {};
    (await Promise.all(imgJsonPromises)).forEach(img => {
      imgJson[img.key] = img.value;
    });

    const formJson = {
      id,
      locationName,
      postalCode: address.postalCode,
      city: address.city,
      street: address.street,
      houseNumber: address.houseNumber,
      lat: address.coordinates.lat,
      lon: address.coordinates.lon,
      firstName: contact.firstName,
      lastName: contact.lastName,
      email: contact.email,
      mobile: contact.mobile,
      operator: operator || "",
      sensorCardNumber,
      manufacturer: aed.manufacturer,
      model: aed.model,
      serialNumber: aed.serialNumber,
      termsAccepted: termsAccepted.toString()
    };

    const submission = {
      formJson,
      imgJson
    };

    try {
      const response = await fetch(`${apiUrl}/Defis/SubmitRegistration`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(submission)
      });
      if (!response.ok) {
        throw new Error('Failed to submit registration');
      }
      await response.json();
      setSubmitted(true);
    } catch (error) {
      console.error('Error submitting registration:', error);
      alert('Error submitting registration. Please try again.');
    }
  };

  if (submitted) {
    return (
      <div className="registration-container">
        <p>Thank you for the registration, you will receive a confirmation email shortly.</p>
      </div>
    );
  }

  const allRequiredFilled = locationName && address.postalCode && address.city && address.street && address.houseNumber && address.coordinates.lat && address.coordinates.lon && contact.firstName && contact.lastName && contact.email && contact.mobile && termsAccepted && sensorCardNumber && aed.manufacturer && aed.model && aed.serialNumber;

  return (
    <div className="registration-container" style={{ padding: "20px", fontFamily: "Arial" }}>
      {submitted ? (
        <div>
          <p>Thank you for the registration, you will receive a confirmation email shortly.</p>
        </div>
      ) : (
        <form onSubmit={handleSubmit} className="registration-form" style={{ display: "flex", flexDirection: "column", maxWidth: "600px", gap: "15px" }}>
          <label>
            Location Name:
            <input type="text" value={locationName} onChange={(e) => setLocationName(e.target.value)} required />
          </label>
          <label>
            Street:
            <input type="text" value={address.street} onChange={(e) => setAddress(prev => ({ ...prev, street: e.target.value }))} required />
          </label>
          <label>
            House Number:
            <input type="text" value={address.houseNumber} onChange={(e) => setAddress(prev => ({ ...prev, houseNumber: e.target.value }))} required />
          </label>
          <label>
            City:
            <input type="text" value={address.city} onChange={(e) => setAddress(prev => ({ ...prev, city: e.target.value }))} required />
          </label>
          <label>
            Postal Code:
            <input type="text" value={address.postalCode} onChange={(e) => setAddress(prev => ({ ...prev, postalCode: e.target.value }))} required />
          </label>
          <label>
            Latitude:
            <input type="text" value={address.coordinates.lat} onChange={(e) => setAddress(prev => ({
              ...prev,
              coordinates: { ...prev.coordinates, lat: e.target.value }
            }))} required />
          </label>
          <label>
            Longitude:
            <input type="text" value={address.coordinates.lon} onChange={(e) => setAddress(prev => ({
              ...prev,
              coordinates: { ...prev.coordinates, lon: e.target.value }
            }))} required />
          </label>
          <label>
            First Name:
            <input type="text" value={contact.firstName} onChange={(e) => setContact(prev => ({ ...prev, firstName: e.target.value }))} required />
          </label>
          <label>
            Last Name:
            <input type="text" value={contact.lastName} onChange={(e) => setContact(prev => ({ ...prev, lastName: e.target.value }))} required />
          </label>
          <label>
            Email:
            <input type="email" value={contact.email} onChange={(e) => setContact(prev => ({ ...prev, email: e.target.value }))} required />
          </label>
          <label>
            Mobile Number:
            <input type="text" value={contact.mobile} onChange={(e) => setContact(prev => ({ ...prev, mobile: e.target.value }))} required />
          </label>
          <label>
            Operator (optional):
            <input type="text" value={operator} onChange={(e) => setOperator(e.target.value)} />
          </label>
          <label>
            PULS KISS Security Card Number:
            <input type="text" value={sensorCardNumber} onChange={(e) => setSensorCardNumber(e.target.value)} required />
          </label>
          <label>
            AED Manufacturer:
            <input type="text" value={aed.manufacturer} onChange={(e) => setAED(prev => ({ ...prev, manufacturer: e.target.value }))} required />
          </label>
          <label>
            AED Model:
            <input type="text" value={aed.model} onChange={(e) => setAED(prev => ({ ...prev, model: e.target.value }))} required />
          </label>
          <label>
            AED Serial Number:
            <input type="text" value={aed.serialNumber} onChange={(e) => setAED(prev => ({ ...prev, serialNumber: e.target.value }))} required />
          </label>
          <label>
            AED Photos:
            <input type="file" multiple onChange={handlePhotoUpload(setAEDPhotos, true)} />
          </label>
          <label>
            Location Photos:
            <input type="file" multiple onChange={handlePhotoUpload(setLocationPhotos, true)} />
          </label>
          <label>
            Sensor Photo:
            <input type="file" onChange={handlePhotoUpload(setSensorPhotos)} />
          </label>
          <label>
            <input type="checkbox" checked={termsAccepted} onChange={(e) => setTermsAccepted(e.target.checked)} />
            I agree to the <a href="https://defiapp.at/documents/DataProtectionRegulation.pdf" target="_blank">Data Protection Regulation</a> and
            <a href="https://defiapp.at/documents/InsuranceConditions.pdf" target="_blank">Insurance Conditions</a>.
          </label>
          <button type="submit" disabled={!allRequiredFilled}>Register</button>
        </form>
      )}
    </div>
  );

};

export default Registration;
