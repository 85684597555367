import React, { useState } from 'react';
import DefiWizardContext, { DefiWizardContextProps } from './DefiWizardContext';
import AddressList from './AddressList';
import ContactList from './ContactList';
import OperatorList from './OperatorList';
import DeviceList from './DeviceList';
import LocationList from './LocationList'; 

const DefiWizard: React.FC = () => { // No more need for DefiWizardProps
  const [step, setStep] = useState(1);

  // Initialize state variables
  const [userAccessLevel, setUserAccessLevel] = useState(0);
  const [idLocation, setIdLocation] = useState(0);
  const [idAddress, setIdAddress] = useState(0);
  const [idContact, setIdContact] = useState(0);
  const [idOperator, setIdOperator] = useState(0);
  const [idVendor, setIdVendor] = useState(0);
  const [idDefibrillatorTracker, setIdDefibrillatorTracker] = useState(0);
  const [idCabinetSensor, setIdCabinetSensor] = useState(0);
  const [idDefibrillator, setIdDefibrillator] = useState(0);

  const contextValue: DefiWizardContextProps = {
    userAccessLevel,
    setUserAccessLevel,
    idLocation,
    setIdLocation,
    idAddress,
    setIdAddress,
    idContact,
    setIdContact,
    idOperator,
    setIdOperator,
    idVendor,
    setIdVendor,
    idDefibrillatorTracker,
    setIdDefibrillatorTracker,
    idCabinetSensor,
    setIdCabinetSensor,
    idDefibrillator,
    setIdDefibrillator,
    updateIdByDeviceType: (deviceType: number, id: number) => {
      switch (deviceType) {
        case 1:
          setIdDefibrillatorTracker(id);
          break;
        case 3:
          setIdCabinetSensor(id);
          break;
        case 4:
          setIdDefibrillator(id);
          break;
        default:
          break;
      }
    },
  };


  const renderStep = () => {
    switch (step) {
      case 1:
        return <AddressList />;
      case 2:
        return <ContactList />;
      case 3:
        return <OperatorList />;
      case 4:
        return <DeviceList idDevice={idDefibrillatorTracker} idDeviceType={1} />;
      case 5:
        return <DeviceList idDevice={idCabinetSensor} idDeviceType={3} />;
      case 6:
        return <DeviceList idDevice={idDefibrillator} idDeviceType={4} />;
      case 7:  // Added LocationList as a new step
        return <LocationList />;
      default:
        return <div>Invalid Step</div>;
    }
  };

  const nextStep = () => {
    if (step < 7) {
      setStep(step + 1);
    }
  };

  const prevStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  return (
    <div className="defi-wizard-container">
      {renderStep()}
      <div className="defi-wizard-buttons">
        {step > 1 && <button onClick={prevStep} className="defi-wizard-button">Back</button>}
        {step < 7 && <button onClick={nextStep} className="defi-wizard-button">Next</button>}
      </div>
    </div>
  );
};

export default DefiWizard;
