import React, { useState, useEffect } from 'react';
import './EventList.css';  // Importing CSS for the spinner

interface Event {
  id: number;
  curr_timestamp: string;
  eventDisplayTextDe: string;
}

interface EventListProps {
  idLocation: number;
}

const EventList: React.FC<EventListProps> = ({ idLocation }) => {
  const [eventList, setEventList] = useState<Event[]>([]);
  const [isLoading, setIsLoading] = useState(true);  // Loading state

  useEffect(() => {
    setIsLoading(true); // Set loading state to true before fetching
    async function fetchEvents() {
      try {
        const response = await fetch(`/api/Defis/GetEventList?idLocation=${idLocation}`);  // Pass idLocation in the API request
        if (!response.ok) {
          throw new Error(`Error fetching events: ${response.statusText}`);
        }
        const data: Event[] = await response.json();
        setEventList(data);
        setIsLoading(false); // Set loading state to false after fetching
      } catch (error) {
        console.error('Error fetching events:', error);
        setIsLoading(false); // Set loading state to false if an error occurs
      }
    }
    fetchEvents();
  }, [idLocation]);  // Add idLocation as a dependency

  return (
    <div className="list-container">
      <h3 className="list-header">Events</h3>
      {isLoading ? (
        <div className="spinner"></div>  // CSS Spinner
      ) : (
        <ul>
          {eventList.map((event) => (
            <li key={event.id}>
              {event.eventDisplayTextDe} - {event.curr_timestamp}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default EventList;
