import React, { useState, useEffect } from 'react';
import './AedStatus.css';

interface IAedStatus {
  statusCode: string;
  status: string;
  reason: string;
  details?: string;
}

interface AedStatusProps {
  idLocation: number;
  email: string;
}

const AedStatus: React.FC<AedStatusProps> = ({ idLocation, email }) => {
  const [aedStatus, setAedStatus] = useState<IAedStatus | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);
  const [reconditionComment, setReconditionComment] = useState<string>('');

  useEffect(() => {
    const fetchAedStatus = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`/api/Defis/GetAedStatus?idLocation=${idLocation}`);
        const data = await response.json();
        setAedStatus(data.jsn);
      } catch (error) {
        console.error('Error fetching AED status:', error);
      }
      setIsLoading(false);
    };
    fetchAedStatus();
  }, [idLocation]);

  const handleRecondition = async () => {
    const idActionType = 1; // Replace with the actual idActionType for reconditioning
    
    if (!reconditionComment.trim()) {
      alert('Please enter a comment for reconditioning.');
      return;
    }
  
    const actionData = {
      idLocation,
      idActionType,
      emailaddress: email, // Use the passed email prop
      comment: reconditionComment,
    };
  
    try {
      const response = await fetch(`/api/Defis/UpsertAction`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(actionData),
      });
  
      const result = await response.json();
  
      if (response.ok && result.status === 'success') {
        setAedStatus(prevStatus => ({
          ...prevStatus,
          statusCode: 'OK',
          status: 'Einsatzbereit',
          reason: '',
          details: prevStatus?.details,
        }));
        setReconditionComment('');
      } else {
        throw new Error(result.msg || 'An unknown error occurred while upserting the action.');
      }
    } catch (error) {
      console.error('Error upserting action:', error);
      // Check if error is an instance of Error and has a message property
      if (error instanceof Error) {
        alert(`Error: ${error.message}`);
      } else {
        alert('An unknown error occurred while upserting the action.');
      }
    }
  };

  const isStatusOk = aedStatus?.statusCode === 'OK';

  return (
    <div className="aed-status-container">
      <h3>AED Status</h3>
      {isLoading ? (
        <div className="spinner"></div>
      ) : (
        <div>
          <div className={`status-indicator ${isStatusOk ? 'status-ok' : 'status-not-ok'}`}></div>
          {!isStatusOk && (
            <div>
              <p className="status-reason">Reason: {aedStatus?.reason}</p>
              <button className="details-button" onClick={() => setShowDetailsModal(true)}>Show Details</button>
            </div>
          )}

          {showDetailsModal && aedStatus && (
            <div className="modal">
              <p>Details: {aedStatus.details}</p>
              <button onClick={() => setShowDetailsModal(false)}>Close</button>
            </div>
          )}

          <div className={`recondition-form ${isStatusOk ? 'disabled' : ''}`}>
            <textarea
              className="comment-field"
              value={reconditionComment}
              onChange={(e) => setReconditionComment(e.target.value)}
              placeholder="Enter comment for reconditioning..."
              required
              disabled={isStatusOk}
            />
            <button className="recondition-button" onClick={handleRecondition} disabled={isStatusOk}>Recondition AED</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AedStatus;
